import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  role: null,
  login: null,
  name: null,
  surname: null,
  utterDate: [4, 2023, 3],
  users: [],
  tele_id: null,
  mail:null
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      const { id, role, login, name, utterDate, surname, mail } = action.payload;
      state.id = id;
      state.name = name;
      state.surname = surname;
      state.login = login;
      state.role = role;
      state.utterDate = utterDate;
      state.mail = mail;
    },
    logout: (state) => {
      return initialState;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setTeleID: (state, action) => {
      state.tele_id = action.payload;
    },
  },
});

export const { login, logout, setUsers, setTeleID } = userSlice.actions;

export default userSlice.reducer;
