import styled, { css } from "styled-components";
import { styles } from "../../constants/styleValues";

export const CalendarGrandContainer = styled.div`
  ${styles.flexRow}
  justify-content: flex-start;
  border: 1px solid black;
  /* margin-left: 5%; */
  width: 100%;
  /* margin-bottom: 100px; */
`;

export const CalendarContainer = styled.div`
  user-select: none;
  /* margin-top: 50px; */
  ${styles.flexCol}
  width: 100%;
  /* max-width: 660px; */
  /* gap: 4px; */
`;

export const CalendarHead = styled.div`
  ${styles.flexRow}
  width: 100%;
  box-sizing: border-box;
  padding: min(0.5vw, 10px) min(1.5vw, 30px);
  font-size: min(1.5vw, 30px);
  color: #463b2d;
  background-color: rgb(234 184 153);
  /* border-radius: min(1vw, 20px) min(1vw, 20px) 0 0; */
`;

export const CalendarHeadMobile = styled(CalendarHead)`
  padding: min(2vw, 10px) min(6vw, 30px);
  font-size: min(4vw, 20px);
  border-radius: min(4vw, 20px) min(4vw, 20px) 0 0;
`;

export const CalendarBody = styled.div`
  ${styles.flexCol}
  padding-top: min(10px, 0.5vw);
  width: 100%;
  gap: min(10px, 0.5vw);
  background-color: #ffffff;
  /* border-radius: 0 0 min(4vw, 20px) min(4vw, 20px); */
`;

export const CalendarBodyMobile = styled(CalendarBody)`
  border-radius: 0 0 min(2vw, 20px) min(2vw, 20px);
`;

export const CalendarRow = styled.div`
  ${styles.flexRow}
  &:nth-child(2) {
    /* margin-top: 15px; */
  }
  width: 100%;
  &:last-child {
    margin-bottom: min(10px, 0.5vw);
  }
  justify-content: space-around;
`;

export const CalendarDay = styled.div`
  ${styles.flexCol}
  text-align: center;
  background-color: ${(props) =>
    props.enabled && !props.isFuture ? props.c : "transparent"};
  /* background-color: ${(props) =>
    props.enabled && !props.isFuture ? "#6c6c6c" : "transparent"}; */
  width: min(66px, 3.3vw);
  height: min(66px, 3.3vw);
  font-size: min(1.2vw, 24px);
  ${(props) =>
    props.isMobile &&
    css`
      width: ${props.size}px;
      height: ${props.size}px;
      font-size: ${props.size / 3}px;
    `}
  color: ${(props) => (props.selected ? "#3a3a3a" : "#606060")};
  /* cursor: ${(props) => (props.enabled ? "pointer" : "default")}; */
  overflow: hidden;
  font-weight: 700;
  box-sizing: border-box;
  /* aspect-ratio: 1 / 1; */
  opacity: ${(props) => (props.enabled ? (props.isFuture ? 0.5 : 1) : 0.2)};
  border-radius: 100px;
  ${(props) =>
    props.type &&
    props.enabled &&
    !props.isFuture &&
    css`
      background-color: ${(props) => (props.ok ? "#c5d47c" : "#cdcdcd")};
    `}
  /* ${(props) =>
    props.isToday && props.enabled
      ? `
    // transform: scale(1.13);
    // box-shadow: 0 0 min(0.7vw, 7px) #000000;
    // filter: brightness(0.95);
    // text-shadow: 0 0 min(0.3vw, 3px) black;
    border: min(0.2vw, 4px) solid rgb(21, 21, 20);
    // color: white;
    border-radius: 100%;
    z-index: 1;
  `
      : `
    &:hover {
      filter: brightness(0.95);
    }
  `} */
  ${(props) =>
    props.isToday &&
    props.enabled &&
    `text-decoration: underline;
    text-underline-offset: min(0.2vw, 4px);
  `}

  &:first-child {
    margin-left: min(10px, 0.5vw);
  }
  &:last-child {
    margin-right: min(10px, 0.5vw);
  }
`;

export const CalendarDayMobile = styled(CalendarDay)`
  font-size: min(3.4vw, 17px);
`;

export const CalendarDayCircle = styled.div`
  position: absolute;
  ${styles.flexCol}
  border-radius: 100%;
  width: 75%;
  aspect-ratio: 1 / 1;
  background: radial-gradient(closest-side, white 80%, transparent 80% 100%),
    conic-gradient(hotpink ${(props) => props.percent}%, pink 0);
`;

export const CalendarLabel = styled(CalendarDay)`
  width: calc((100% / 7));
  height: min(40px, 2vw);
  font-size: min(1vw, 20px);
  ${(props) =>
    props.isMobile &&
    css`
      height: ${props.size / 1.5}px;
      font-size: ${props.size / 3}px;
    `}
  /* aspect-ratio: 7 / 3; */
  opacity: 1;
  color: black;
  font-weight: 500;
  border-radius: 0;
  background-color: #ffe476;
  /* &:first-child {
    border-radius: 10px 0 0 10px;
  }
  &:last-child {
    border-radius: 0 10px 10px 0;
  } */
`;

export const CalendarLabelMobile = styled(CalendarLabel)`
  font-size: min(3vw, 15px);
  /* &:first-child {
    border-radius: 10px 0 0 10px;
  }
  &:last-child {
    border-radius: 0 10px 10px 0;
  } */
`;

export const FlexDay = styled.div`
  position: absolute;
  ${styles.flexRow}
  pointer-events: none;
`;
