export class styles {
  defaultHover = `
    
  `;

  static maxWidth = "1500px";
  static defaultWidth = "90%";
  static flexRow = `
    display: flex; 
    justify-content: center;
    align-items: center;`;
  static flexCol = `display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;`;
  static defaultBackgroundColor1 = "#eceada";
  static defaultBackgroundColor2 = "#fffad6";
  static defaultMargin = "40px";
  static hideScroll = `
    ::-webkit-scrollbar {
      display: none;
    }
  `;
  static ellipsis = `
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `;
  static cellSize = "60px";
  static button = `
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    border-radius: min(0.7vw, 7px);
    box-sizing: border-box;
    width: 100%;
    font-weight: 600;
    user-select: none;
  `;
  static disabledButton = `
    background-color: rgb(233 233 233);
    color: #ababab;
    border: min(0.1vw, 1px) solid #bcbcbc;
  `;
  static acceptButton = `
    cursor: pointer;
    background-color: rgb(120 221 140);
    color: rgb(58 139 67);
    border: min(0.1vw, 1px) solid rgb(70 114 76);
    &:hover {
      filter: brightness(1.1);
    }
  `;
  static declineButton = `
    cursor: pointer;
    background-color: #dd8080;
    color: #723333;
    border: min(0.1vw, 1px) solid #732a2a;
    &:hover {
      filter: brightness(1.1);
    }
  `;
  static dangerButton = `
    cursor: pointer;
    background-color: rgb(255, 195, 117);
    color: rgb(122, 93, 55);
    border: min(0.1vw, 1px) solid rgb(122, 93, 55);
    &:hover {
      filter: brightness(1.1);
    }
  `;
  static defaultHover = `
    filter: brightness(1.05);
  `;
}

export class modals {
  static backIn = `
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `;
  static backOut = `
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  `;
  static modalIn = `
    from {
      top: -500px;
    }
    to {
      top: 60px;
    }
  `;
  static modalOut = `
    from {
      top: 60px;
    }
    to {
      top: -500px;
    }
`;
  static background = `
    position: fixed;
    opacity: 1;
    width: 100vw;
    height: 100vh;
    z-index: 10;
  `;
  static defaultModal = `
    position: fixed;
    transform: translate(-50%);
    top: 60px;
    left: 50%;
    width: 700px;
    height: auto;
    border-radius: 10px;
    box-shadow: #454545 0px 0px 10px;
    background-color: rgb(255 255 239);
  `;
}

export class colors {
  static c1 = "#74e2d1";
  static c2 = "#c1ffe6";
  static c3 = "#52b2a8";
  static gr1 = [`rgb(255 149 149)`, `#ffde90`, `#e9ff69`, `#bbff69`];
  static gr = [`#eb6666`, `#eec462`, `#ccdf60`, `#9bd159`];
  static pal1 = [`#536F01`, `#E8B100`, `#FCE2AD`, `#8A9994`, `#3F6358`];
  static border1px = "min(0.1vw, 1px) solid grey";
}
