import styled, { css, keyframes } from "styled-components";
import { colors, styles } from "../../constants/styleValues";
import accept from "../../svgs/accept.svg";
import denied from "../../svgs/denied.svg";
import trash from "../../svgs/trash.svg";
import arrowR from "../../svgs/arrowR.svg";
import backLight from "../../images/back-light.jpeg";
import bear from "../../images/bear.png";
import done from "../../svgs/done.svg";
import fail from "../../svgs/fail.svg";
import nulll from "../../svgs/null.svg";

export const HabitIGrandContainer = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: min(50px, 2.5vw);
  padding: min(30px, 1.5vw);
  border-radius: min(40px, 2vw);
  /* background-color: #ffbe76; */
  /* box-shadow: 0 1px 1px #967c63; */
`;

export const BearContainer = styled.div`
  width: ${(props) =>
    props.isMobile ? "min(40vw, 800px)" : "min(15vw, 300px)"};
  height: ${(props) =>
    props.isMobile ? "min(40vw, 800px)" : "min(15vw, 300px)"};
  background: url(${bear});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const BadPageComment = styled.div`
  font-size: min(34px, 1.7vw);
  font-weight: 600;
  color: #2d2316;
  white-space: pre-wrap;
`;

const anim = keyframes`
  from {
    font-size: min(40px, 2vw);
  }
  50% {
    font-size: min(42px, 2.1vw);
  }
  to {
    font-size: min(40px, 2vw);
  }
`;

export const HeaderTutor = styled.div`
  font-size: min(40px, 2vw);
  font-weight: 600;
  color: #2d2316;
  position: absolute;
  left: min(40px, 2vw);
  font-style: italic;
  animation: ${anim} 2s linear infinite;
  cursor: pointer;
  text-decoration: underline;
`;

export const HeaderNickName = styled.div`
  font-size: min(40px, 2vw);
  font-weight: 600;
  color: #2d2316;
  position: absolute;
  right: min(40px, 2vw);
`;

export const AddHabitI = styled.div`
  position: absolute;
  left: 0;
  top: min(50px, 2.5vw);
  width: min(360px, 18vw);
  height: min(40px, 2vw);
  line-height: min(40px, 2vw);
  font-size: min(20px, 1vw);
  ${(props) =>
    props.isMobile &&
    css`
      top: min(150px, 7.5vw);
      width: min(600px, 30vw);
      height: min(80px, 4vw);
      line-height: min(80px, 4vw);
      font-size: min(34px, 1.7vw);
    `}
  border-radius: 0 0 min(30px, 1.5vw) min(30px, 1.5vw);
  background-color: #3e883e;
  text-align: center;
  color: white;
  transition: filter 0.2s;
  cursor: pointer;
  &:hover {
    filter: brightness(1.2);
  }
`;

export const HabbitITop = styled.div`
  width: 90%;
  max-width: 1800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HabitIMotivation = styled.div`
  white-space: pre-wrap;
  text-transform: uppercase;
  /* padding-right: min(40px, 2vw); */
  text-align: end;
  line-height: min(40px, 2vw);
  font-size: min(26px, 1.3vw);
  ${(props) =>
    props.isMobile &&
    css`
      line-height: min(80px, 4vw);
      font-size: min(40px, 2vw);
      width: 100vw;
      text-align: center;
      margin-bottom: min(60px, 3vw);
    `}
  font-style: italic;
`;

export const HabitIContainer = styled.div`
  ${styles.flexCol}
  /* align-items: flex-start; */
  justify-content: space-between;
  height: 100%;
  width: 68%;
  /* margin-top: min(30px, 1.5vw); */
  /* max-width: 1000px; */
`;

export const HeaderGrand = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: min(120px, 6vw);
  background-color: #eab899;
  box-shadow: 0 1px min(4px, 0.2vw) grey;
  z-index: 10;
`;

export const HeaderEl = styled.div`
  padding: 0 min(20px, 1vw);
  line-height: min(120px, 6vw);
  font-size: min(30px, 1.5vw);
  user-select: text;
  color: ${(props) => (props.selected ? "#684926" : "#684926")};
  font-weight: ${(props) => (props.selected ? 600 : 500)};
  /* border-bottom: min(4px, 0.2vw) solid #684926; */
  &:first-child {
    margin-left: min(30px, 1.5vw);
  }
`;

export const SelectedHeader = styled.div`
  width: 100%;
  height: min(8px, 0.4vw);
  border-radius: min(8px, 0.4vw) min(8px, 0.4vw) 0 0;
  position: absolute;
  background-color: #684926;
  bottom: 0;
  left: 0;
`;

export const HabitIHeader = styled.div`
  ${styles.flexRow}
  font-size: ${(props) =>
    props.isMobile ? "min(100px, 5vw)" : "min(60px, 3vw)"};
  ${(props) =>
    props.isMobile &&
    css`
      margin-bottom: min(70px, 3.5vw);
    `}
  box-sizing: border-box;
  padding: min(14px, 0.7vw) 0;
  text-transform: uppercase;
`;

export const HabitIMonthChanger = styled.div`
  ${styles.flexRow}
  width: ${(props) =>
    props.isMobile ? "min(1400px, 70vw)" : "min(500px, 25vw)"};
  ${(props) =>
    props.isMobile &&
    css`
      margin-bottom: min(50px, 2.5vw);
    `}
`;

export const HabitMobileRight = styled.div`
  width: min(65vw, 1300px);
  display: flex;
  overflow-x: scroll;
  border-right: 1px solid black;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const HabitIButton = styled.div`
  ${styles.flexRow}
  position: absolute;
  background: url(${arrowR});
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${(props) => (props.isCurrent ? "#e4e4e4" : "#fbffad")};
  color: ${(props) => (props.isCurrent ? "#b6b6b6" : "#796954")};
  cursor: ${(props) => (props.isCurrent ? "default" : "pointer")};

  &:hover {
    ${(props) =>
      !props.isCurrent &&
      `
      filter: brightness(1.1);
    `}
  }
  box-shadow: 0 0 5px grey;
  width: min(5vw, 100px);
  height: min(5vw, 100px);
  ${(props) =>
    props.isMobile &&
    css`
      width: min(8vw, 160px);
      height: min(8vw, 160px);
    `}
  border-radius: 100%;
  /* padding: 10px 0; */
`;

export const HabitIPrevMonth = styled(HabitIButton)`
  left: 0;
  transform: scale(-1);
`;

const boxIn = keyframes`
  from {
    width: min(10px, 0.5vw);
  }
  to {
    width: min(260px, 13vw)
  }
`;

const boxOut = keyframes`
  from {
    width: min(260px, 13vw)
  }
  to {
    width: min(10px, 0.5vw);
  }
`;

const boxMobIn = keyframes`
  from {
    width: min(10px, 0.5vw);
  }
  to {
    width: min(600px, 30vw)
  }
`;

const boxMobOut = keyframes`
  from {
    width: min(600px, 30vw);
  }
  to {
    width: min(10px, 0.5vw);
  }
`;

export const HabitChooseBox = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: ${(props) =>
    props.isMobile ? "min(600px, 30vw)" : "min(260px, 13vw)"};
  height: ${(props) =>
    props.isMobile ? "min(200px, 10vw)" : "min(80px, 4vw)"};
  bottom: max(-15px, -0.75vw);
  border-radius: min(15px, 0.75vw);
  right: min(50px, 2.5vw);
  background-color: #ffffce;
  z-index: 10;
  animation: ${(props) =>
      props.isMobile
        ? !props.out
          ? boxMobIn
          : boxMobOut
        : !props.out
        ? boxIn
        : boxOut}
    0.15s;
`;

export const CheckButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.isMobile ? "min(200px, 10vw)" : "min(80px, 4vw)")};
  height: ${(props) =>
    props.isMobile ? "min(200px, 10vw)" : "min(80px, 4vw)"};
  transition: filter 0.2s, transform 0.2s;
  &:hover {
    filter: brightness(1.1);
    transform: scale(1.1);
  }
`;

const CheckButton = styled.div`
  width: ${(props) =>
    props.isMobile ? "min(150px, 7.5vw)" : "min(64px, 3.2vw)"};
  height: ${(props) =>
    props.isMobile ? "min(150px, 7.5vw)" : "min(64px, 3.2vw)"};
`;

export const DoneBack = styled(CheckButton)`
  position: absolute;
  background-color: rgb(172, 255, 165);
  border-radius: 100%;
`;

export const DoneButton = styled(CheckButton)`
  background: url(${done});
  background-position: center;
  background-size: 123%;
  background-repeat: no-repeat;
  filter: invert(40%) sepia(99%) saturate(410%) hue-rotate(68deg)
    brightness(99%) contrast(93%);
`;

export const FailBack = styled(CheckButton)`
  position: absolute;
  background-color: rgb(255, 202, 202);
  border-radius: 100%;
`;

export const FailButton = styled(CheckButton)`
  background: url(${fail});
  background-position: center;
  background-size: 123%;
  background-repeat: no-repeat;
  filter: invert(27%) sepia(30%) saturate(3375%) hue-rotate(333deg)
    brightness(97%) contrast(85%);
`;

export const NullBack = styled(CheckButton)`
  position: absolute;
  border-radius: 100%;
  background-color: rgb(243, 243, 243);
`;

export const NullButton = styled(CheckButton)`
  background: url(${nulll});
  background-position: center;
  background-size: 123%;
  background-repeat: no-repeat;
  filter: invert(59%) sepia(0%) saturate(1682%) hue-rotate(227deg)
    brightness(72%) contrast(91%);
`;

export const HabitINextMonth = styled(HabitIButton)`
  right: 0;
`;

export const HabitIMonth = styled(HabitIHeader)`
  padding: 0;
  flex-shrink: 0;
  height: min(5vw, 100px);
  width: min(400px, 20vw);
  font-size: min(28px, 1.4vw);
  ${(props) =>
    props.isMobile &&
    css`
      height: min(8vw, 160px);
      width: min(1260px, 62vw);
      font-size: min(60px, 3vw);
      margin-bottom: 0;
    `}
  overflow: hidden;
  color: #47423b;
  background-color: rgb(255 228 118);
`;

export const HabitIActivityContainer = styled.div`
  ${styles.flexCol}/* flex-shrink: 0; */
  /* background-color: antiquewhite; */
  /* gap: min(16px, 0.8vw); */
  /* max-width: 1200px; */
  /* background-color: #2f3333; */
`;

export const HabitIActivityRow = styled.div`
  ${styles.flexRow}
  /* align-items: flex-end; */
  /* width: ${(props) => props.monthWidth * 2.5 + 22.5}%; */
  /* gap: min(10px, 0.5vw); */
  /* background-color: #e47f7f; */
  /* flex-shrink: 0; */
  box-sizing: border-box;
  background-color: white;
  /* border-radius: min(40px, 2vw); */
  border-bottom: 1px solid black;
  /* &:hover {
    filter: ${(props) => (props.nonHover ? "" : "brightness(0.92)")};
  } */
  /* border: 1px solid black; */
`;

const HabitILeft = styled.div`
  ${styles.flexRow}
  width: min(360px, 18vw);
  height: min(50px, 2.5vw);
  font-size: min(20px, 1vw);
  ${(props) =>
    props.isMobile &&
    css`
      width: min(600px, 30vw);
      height: min(150px, 7.5vw);
      font-size: min(40px, 2vw) !important;
    `}
  /* aspect-ratio: 5 / 1; */
  flex-shrink: 0;
  box-sizing: border-box;
  padding: min(1vw, 20px);
  line-height: 1;
  border-left: 1px solid black;
  overflow: hidden;
`;

const HabitRight = styled.div`
  ${styles.flexRow}
  width: min(50px, 2.5vw);
  height: min(50px, 2.5vw);
  box-sizing: border-box;
  font-size: min(1vw, 20px);
  ${(props) =>
    props.isMobile &&
    css`
      width: min(150px, 7.5vw);
      height: min(150px, 7.5vw);
      box-sizing: border-box !important;
    `}/* aspect-ratio: 5 / 9; */
  /* flex-shrink: 0; */
  /* overflow: hidden; */
  /* &:last-child {
    margin-right: min(10px, 0.5vw);
  } */
  /* bottom: ${colors.border1px}; */
`;

export const HabitIFakeDay = styled(HabitRight)`
  /* visibility: hidden; */
  /* margin-right: max(-10px, -0.5vw); */
`;

export const HabitIHabitName = styled(HabitILeft)`
  overflow: hidden;
  justify-content: flex-start;
  /* background-color: #fff8e0; */
  /* margin-right: min(10px, 0.5vw); */
  font-style: italic;
  /* border-bottom: 1px solid black; */
  /* border-radius: min(40px, 2vw); */
  border-right: 1px solid black;
  /* word-break: break-all; */
  cursor: pointer;

  /* &:hover {
    filter: brightness(0.9);
  } */
`;

export const HabitIRedactor = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: ${(props) =>
    props.isMobile ? "min(1200px, 60vw)" : "min(700px, 35vw)"};
  background-color: rgb(255 255 239);
  gap: min(20px, 1vw);
  /* height: min(200px, 10vw); */
  border-radius: min(20px, 1vw) min(20px, 1vw) min(20px, 1vw) 0;
  left: ${(props) =>
    props.isMobile ? "min(600px, 30vw)" : "min(360px, 18vw)"};
  bottom: 0;
  z-index: 20;
  box-shadow: 0 1px 4px black;
`;

export const DeleteIt = styled.div`
  position: absolute;
  right: min(20px, 1vw);
  top: min(10px, 0.5vw);
  width: min(40px, 2vw);
  height: min(40px, 2vw);
  background: url(${trash});
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  z-index: 1;
  opacity: 0.7;
  transition: opacity 0.2s;
  &:hover {
    opacity: 1;
  }
  filter: invert(21%) sepia(24%) saturate(3159%) hue-rotate(328deg)
    brightness(89%) contrast(84%);
  cursor: pointer;
`;

export const DeletModal = styled.div`
  position: absolute;
  background-color: #676767;
  z-index: 10;
  width: 94%;
  border-radius: min(10px, 0.5vw);
  top: min(56px, 2.8vw);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DeleteLabel = styled.div`
  text-align: center;
  margin-top: min(10px, 0.5vw);
  margin-bottom: min(10px, 0.5vw);
  height: min(40px, 2vw);
  line-height: min(40px, 2vw);
  font-size: min(24px, 1.2vw);
  ${(props) =>
    props.isMobile &&
    css`
      height: min(80px, 4vw);
      line-height: min(80px, 4vw);
      font-size: min(48px, 2.4vw);
    `}
  color: white;
`;

export const HabitIInput = styled.textarea`
  width: 90%;
  height: min(88px, 4.4vw);
  font-size: min(24px, 1.2vw);
  border-radius: min(10px, 0.5vw);
  padding: min(10px, 0.5vw);
  /* margin-top: min(20px, 1vw); */
  resize: none;
  &:focus {
    outline: none;
    border: 2px solid rgb(132 117 48);
  }
`;

export const DiaryQ = styled.div`
  width: ${(props) => (props.ok ? "min(9vw, 180px)" : "min(3vw, 60px)")};
  height: 100%;
  padding: min(16px, 0.8vw) min(6px, 0.3vw);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 10px; */
  background-color: ${(props) =>
    props.ok ? "rgb(255, 228, 118)" : "transparent"};
  font-size: min(16px, 0.8vw);
  border-right: 1px solid black;
  border-top: ${(props) => (props.ok ? "1px solid black" : "")};
`;

export const DayS = styled.div`
  height: min(6px, 0.3vw);
  font-size: min(20px, 1vw);
  line-height: min(6px, 0.3vw);
  width: min(56px, 2.8vw);
  background-color: rgb(248, 150, 150);
  text-align: center;
`;

export const LabelHui = styled.div`
  width: 90%;
  text-align: center;
  font-size: min(40px, 2vw);
`;

export const Selector = styled.select`
  width: min(400px, 20vw);
  height: min(50px, 2.5vw);
  font-size: min(24px, 1.2vw);
  position: absolute;
  right: min(20px, 1vw);
  border-radius: min(10px, 0.5vw);
`;

export const HabitIHabitNameHeader = styled(HabitILeft)`
  /* aspect-ratio: 9 / 1; */
  /* margin-right: min(10px, 0.5vw); */
  font-size: min(30px, 1.5vw);
  border-top: 1px solid black;
  border-right: 1px solid black;
  text-transform: uppercase;
`;

export const HabitIActivityDefault = styled(HabitRight)`
  border-right: 1px solid black;
  flex-shrink: 0;
  color: ${(props) =>
    props.ok ? (props.status === "done" ? "#2d9d23" : "#a42d2d") : "grey"};
  background-color: ${(props) =>
    props.ok
      ? props.status === "done"
        ? "rgb(172 255 165)"
        : "rgb(255 202 202)"
      : "#f3f3f3"};
  font-size: ${(props) =>
    props.isMobile ? "min(80px, 4vw)" : "min(34px, 1.7vw)"};
  cursor: ${(props) => (!props.isFuture ? "pointer" : "default")};
  ${(props) =>
    props.isToday &&
    css`
      background-color: white;
      box-sizing: content-box;
      margin-bottom: ${(props) => (props.last ? "-3px" : "0")};
      border-left: 2px solid black;
      /* border-bottom: 1px solid black; */
      border-right: 3px solid black;
      border-bottom: ${(props) => (props.last ? "3px" : "0")} solid black;
      background-color: #f5f2bb;
      /* border-top: 2px solid black; */
    `}
  ${(props) =>
    props.isFuture &&
    css`
      background-color: #c9c9c9;
    `} /* background: url(${(props) =>
    !props.isFuture ? (props.status ? accept : denied) : ""}); */
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: ${(props) => (props.status ? "90%" : "60%")}; */
  /* border-radius: 100%; */
  /* box-shadow: 0 0 min(8px, 0.4vw) #45403c; */
  /* background-color: ${(props) =>
    !props.isFuture
      ? props.status
        ? "rgb(209 255 168)"
        : "rgb(212 111 86)"
      : "transparent"}; */
  /* border: 1px solid
    ${(props) =>
    !props.isFuture
      ? props.status
        ? "rgb(151 189 102)"
        : "rgb(178 84 63)"
      : "none"}; */
  /* background-color: rgb(255 254 244); */
  /* text-shadow: 0 0 1px ${(props) =>
    props.status ? "#2f8833" : "#a15f5f"}; */
  /* font-size: min(2.2vw, 24px); */
  /* ${(props) =>
    props.isToday &&
    `
    background-color: rgb(255 250 168);
  `}
  ${(props) =>
    props.isFuture &&
    `
    opacity: 0.5;
    background-color: rgb(252 252 252);
  `} */
  &:hover {
    filter: brightness(0.92);
    z-index: 2;
  }
`;

export const HabitIActivityFake = styled(HabitRight)`
  visibility: hidden;
`;

export const HabitIActivityCircle = styled.div`
  width: 60%;
  /* aspect-ratio: 1 / 1; */
  border-radius: 100%;
  background-color: ${(props) => (props.status ? "#cd6e6e" : "#4dbb9a")};
  /* border: min(0.3vw, 3px) solid #2f3333; */
`;

export const HabitIActivityDay = styled(HabitRight)`
  /* background-color: #fff8e0; */
  font-size: ${(props) =>
    props.isMobile ? "min(40px, 2vw)" : " min(24px, 1.2vw)"};
  flex-shrink: 0;
  /* border-radius: 100%; */
  font-weight: 600;
  ${(props) =>
    props.isFuture &&
    css`
      background-color: #e6e6e6;
    `}
  border-top: 1px solid black;
  border-right: 1px solid black;
  ${(props) =>
    props.isToday &&
    css`
      box-sizing: content-box;
      /* margin-top: -3px; */
      border-left: 2px solid black;
      background-color: #fffce9;
      /* border-bottom: 1px solid black; */
      border-right: 3px solid black;
      border-top: 3px solid black;
    `}/* ${(props) =>
    props.isToday &&
    `
    background-color: rgb(255 220 73);
  `}
  ${(props) =>
    props.isFuture &&
    `
    opacity: 0.5;
    background-color: rgb(185 185 185);
  `} */
`;

export const Background = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #fff7dc;
  /* background: linear-gradient(
    90deg,
    rgba(235, 204, 164, 1) 0%,
    rgba(255, 240, 192, 1) 100%
  ); */
`;

export const KekW = styled.div`
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  top: calc(min(120px, 6vw));
  height: calc(100vh - min(120px, 6vw));
`;
