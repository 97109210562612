import HabitTracker from "../components/HabitTracker/HabitTracker";
import MainPage from "../components/MainPage/MainPage";
import Diary from "../components/Diary_results/Diary";
import HabitUserTracker from "../components/HabitUserTracker/HabitUserTracker";
import FourZeroFour from "../components/404/404";
export class router {
  static any = [
    // { path: "/", component: <HabitTracker /> },
    { path: "/ks1uz6hnmTixCph", component: <HabitTracker opa={true} /> },
    // { path: "/habit-tracker", component: <HabitTracker /> },
    { path: "/tracking", component: <HabitUserTracker /> },
    // { path: "/diary", component: <Diary /> },
    { path: "*", component: <FourZeroFour /> },
  ];
}
