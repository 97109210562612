import styled, { keyframes } from "styled-components";
import bear from "../../images/bear-mot2.png";

const bearOut = keyframes`
  from {
    bottom: max(-1vw, -20px);
  }
  to {
    bottom: min(-20vw, -400px);
  }
`;

const bearIn = keyframes`
  from {
    bottom: min(-20vw, -400px);
  }
  to {
    bottom: max(-1vw, -20px);
  }
`;

const bearIdle = keyframes`
  from {
    transform: translate(-50%) rotate(0deg);
  }
  25% {
    transform: translate(-50%) rotate(1deg);
  }
  50% {
    transform: translate(-50%) rotate(0deg);
  }
  75% {
    transform: translate(-50%) rotate(-1deg);
  }
  to {
    transform: translate(-50%) rotate(0deg);
  }
`;

export const LoadingBarBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${(props) =>
    props.isMobile ? "min(18vw, 360px)" : "min(9vw, 180px)"};
  height: ${(props) =>
    props.isMobile ? "min(12vw, 240px)" : "min(6vw, 120px)"};
`;

const barAnim = keyframes`
  from {
    height: min(6vw, 120px);
    opacity: 1;
  }
  50% {
    height: min(3vw, 60px);
    opacity: 0.6;
  }
  to {
    height: min(6vw, 120px);
    opacity: 1;
  }
`;

const barAnim2 = keyframes`
  from {
    height: min(12vw, 240px);
    opacity: 1;
  }
  50% {
    height: min(6vw, 120px);
    opacity: 0.6;
  }
  to {
    height: min(12vw, 240px);
    opacity: 1;
  }
`;

export const LoadingBar = styled.div`
  width: ${(props) => (props.isMobile ? "min(4vw, 80px)" : "min(2vw, 40px)")};
  height: ${(props) =>
    props.isMobile ? "min(12vw, 240px)" : "min(6vw, 120px)"};
  background-color: #4e341b;
  border-radius: 200px;
  animation: ${(props) => (props.isMobile ? barAnim2 : barAnim)} 0.75s linear
    infinite;
  animation-delay: ${(props) => props.delay}s;
`;

export const MotGrand = styled.div`
  position: fixed;
  bottom: max(-1vw, -20px);
  left: 50%;
  transform: translateX(-50%);
  width: min(72vw, 1440px);
  height: min(39vw, 780px);
  background: url(${bear});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform-origin: center;
  z-index: 30;
  animation: ${(props) =>
      props.idle ? bearIdle : props.out ? bearOut : bearIn}
    ${(props) => (props.idle ? "1.37s" : props.out ? "0.15s" : "0.3s")}
    ${(props) => (props.idle ? "linear" : "ease-out")}
    ${(props) => (props.idle ? "infinite" : "")};
`;

export const MotText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  top: min(3vw, 60px);
  left: min(4vw, 80px);
  line-height: min(3vw, 60px);
  position: absolute;
  width: min(64vw, 1360px);
  height: min(17vw, 340px);
  text-align: center;
  font-size: min(40px, 2vw);
  font-weight: 600;
`;
