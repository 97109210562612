import { useSelector } from "react-redux";
import NewHabitModal from "./NewHabit.js/NewHabit";
import RemoveHabit from "./RemoveHabit/RemoveHabit";
import Tutor from "./Tutor/Tutor";

const Modals = () => {
  const modals = useSelector((store) => store.modals);

  return (
    <>
      {modals.newHabit && <NewHabitModal />}
      {modals.removeHabit && <RemoveHabit />}
      {modals.tutor && <Tutor />}
    </>
  );
};

export default Modals;
