import styled, { css } from "styled-components";
import { colors, styles } from "../../constants/styleValues";

export const HabitTrackerContainer = styled.div`
  ${styles.flexRow}
  box-sizing: border-box;
  /* gap: 1px; */
  align-items: flex-start;
  user-select: none;
  transform: translateX(-50%);
  left: 50%;
  width: 90%;
  max-width: fit-content;
  /* max-width: ${styles.maxWidth}; */
`;

export const HabitHeaderLeft = styled.div`
  ${styles.ellipsis}
  &:first-child {
    border-top-left-radius: 10px;
    text-align: center;
    background-color: #c36a6a;
    color: white;
    font-weight: 600;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
  }
  position: relative;
  box-sizing: border-box;
  padding: 0 15px;
  width: 100%;
  height: ${styles.cellSize};
  line-height: ${styles.cellSize};
  background-color: #ffea82;
  color: #606015;
`;

export const LeftContainer = styled.div`
  ${styles.flexCol}
  width: 337px;
  top: 3px;
  gap: 1px;
`;

export const RightContainer = styled.div`
  ${styles.flexCol}
  ${styles.hideScroll}
  /* overflow: scroll; */
  align-items: flex-start;
  justify-content: flex-start;
  top: 3px;
  /* width: ${(props) => props.width}px; */
`;

export const TrackerContainer = styled.div`
  ${styles.flexCol}
  /* margin: ${styles.defaultMargin}; */
  width: auto;
  max-width: ${styles.maxWidth};
  height: auto;
  gap: 20px;
`;

export const HabitHeaders = styled.div`
  ${styles.flexRow}
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: ${(props) => (props.monthWitdh - 1) * 30}px;
  height: ${styles.cellSize};
  gap: 20px;
`;

export const HabitHeader = styled.div`
  width: ${(props) => (props.isFakeElement ? "250px" : `${styles.cellSize}`)};
  height: ${styles.cellSize};
  line-height: ${styles.cellSize};
  flex-shrink: 0;
  font-size: 16px;
  background-color: #c36a6a;
  text-align: center;
  &:first-child {
    border-top-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
  }
`;

export const AddHabitButton = styled.div`
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0px 15px;
  height: ${styles.cellSize};
  line-height: ${styles.cellSize};
  background-color: ${colors.pal1[1]};
  color: green;
  text-align: center;
  align-self: flex-start;
  border-radius: 0 0 10px 10px;
  transition: padding 0.15s, height 0.15s;
  &:hover {
    filter: brightness(1.06);
    padding: 3px 15px 0px 15px;
    height: calc(${styles.cellSize} + 3px);
  }
`;

export const AddHabbitCircle = styled.div`
  position: absolute;
  text-align: center;
  width: ${styles.cellSize};
  height: ${styles.cellSize};
  line-height: calc(${styles.cellSize} - 4px);
  /* border-radius: 100%; */
  box-sizing: border-box;
  border: 1px solid green;
  top: 0;
  right: 0;
  font-size: 30px;
  background-color: ${colors.c1};
  color: green;
  cursor: pointer;
  &:hover {
    filter: brightness(1.1);
  }
`;

export const EmojiSelector = styled.div`
  ${styles.flexRow}
  position: absolute;
  top: 40px;
  width: 140px;
  height: 40px;
  background-color: #000000;
  z-index: 10;
`;

export const Emoji = styled.div`
  width: 30px;
  height: 30px;
`;

export const HabitDesktopCalendarAndOtherGrand = styled.div`
  ${styles.flexRow}
  width: 1800px;
  max-width: 90%;
  /* margin-bottom: min(40px, 2vw); */
  margin-top: min(40px, 2vw);
`;

export const HabitDesktopCalendarAndOther = styled.div`
  ${styles.flexRow}
  align-items: flex-start;
  width: 100%;
  max-width: ${styles.maxWidth};
  gap: 2%;
`;
