import { useEffect, useState } from "react";
import HabitCalendar from "../HabitCalendar/HabitCalendar";
import HabitDesktopHeader from "../HabitDesktopHeader/HabitDesktopHeader";
import HabitImprovedDesktop from "../HabitImprovedDesktop/HabitImprovedDesktop";
import {
  Background,
  HeaderGrand,
  HeaderNickName,
  HeaderTutor,
  KekW,
} from "../HabitImprovedDesktop/style";
import { HabitDesktopCalendarAndOtherGrand } from "../HabitTracker/style";
import Init from "../Init/Init";
import { useNavigate } from "react-router";
import { habitCheckUrl, habitUrl, url } from "../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/slices/userSlice";
import { dateInit } from "../../redux/slices/dateSlice";
import { initHabits } from "../../redux/slices/habitSlice";
import { isMobile } from "react-device-detect";
import MotivationModal from "../MotivationModal";
import DownloadScreen from "../DownloadScreen/DownloadScreen";
import { showTutor } from "../../redux/slices/modalSlice";

const HabitUserTracker = () => {
  const searchQ = new URLSearchParams(document.location.search);

  const userData = useSelector((store) => store.user);
  const modal = useSelector((store) => store.modals);

  const [download, setDownload] = useState(true);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    const tid = searchQ.get("tid") || localStorage.getItem("tid");
    if (tid) {
      const fetchUser = async () => {
        const response = await fetch(`${url}/Diary/get-user-by-id/${tid}`);
        const user = await response.json();

        if (user) {
          const regDate = new Date(user.createdAt);
          user.utterDate = [
            regDate.getMonth() + 1,
            regDate.getFullYear(),
            regDate.getDate(),
          ];

          const response2 = await fetch(`${habitUrl}/${user.id}`);
          const habits = await response2.json();

          const response3 = await fetch(`${habitCheckUrl}/${user.id}`);
          const habitChecks = await response3.json();

          // console.log(habitChecks);
          const dateToString = (d) => {
            const [day, month, year] = [
              d.getDate(),
              d.getMonth(),
              `${d.getFullYear()}`.slice(2),
            ];

            return `${day}.${month}.${year}`;
          };

          const d = dateToString(new Date());

          const plank = Math.ceil(habits.length / 2);

          const checkArray = habitChecks.filter((el) => el.date === d);

          dispatch(
            login({
              id: user.id,
              name: user.name,
              surname: user.surname,
              login: user.login,
              role: user.role,
              utterDate: user.utterDate,
            })
          );

          dispatch(dateInit([0, 2023, 1]));
          dispatch(
            initHabits({
              habits,
              habitChecks,
              isPerfectDay: checkArray.length >= plank && habits.length > 0,
            })
          );

          localStorage.setItem("tid", user.tele_id);

          setTimeout(() => {
            setDownload(false);
          }, 1337);
        } else {
          setTimeout(() => {
            setDownload(false);
          }, 1337);
          navigate("/");
        }
      };

      fetchUser();

      navigate("/tracking");
    } else {
      navigate("/");
    }
  }, []);

  return (
    <>
      {download && <DownloadScreen />}
      {/* <Init /> */}
      {modal.motivationSpeech && <MotivationModal />}
      <HeaderGrand>
        <HeaderTutor
          onClick={() => {
            dispatch(showTutor(true));
          }}
        >
          {"Инструкция"}
        </HeaderTutor>
        <HeaderNickName>{`${userData.name} ${
          userData.surname || ""
        }`}</HeaderNickName>
      </HeaderGrand>
      {!isMobile ? (
        <KekW>
          <Background></Background>
          <HabitDesktopCalendarAndOtherGrand
          // style={{ justifyContent: "flex-end", alignItems: "flex-start" }}
          >
            {/* <HabitDesktopCalendarAndOther> */}
            {/* <HabitListDesktop /> */}
            <HabitDesktopHeader selected={1} />
            <HabitCalendar w={40} type={true} />
            {/* </HabitDesktopCalendarAndOther> */}
          </HabitDesktopCalendarAndOtherGrand>
          <HabitImprovedDesktop type={true} />
        </KekW>
      ) : (
        <KekW>
          <Background></Background>
          <HabitDesktopCalendarAndOtherGrand
            style={{ flexDirection: "column" }}
          >
            {/* <HabitDesktopCalendarAndOther> */}
            {/* <HabitListDesktop /> */}
            <HabitDesktopHeader selected={1} />
            <HabitCalendar w={100} type={true} />
            {/* </HabitDesktopCalendarAndOther> */}
          </HabitDesktopCalendarAndOtherGrand>
          <HabitImprovedDesktop type={true} />
        </KekW>
      )}
    </>
  );
};

export default HabitUserTracker;
