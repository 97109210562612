import {
  Background1,
  ModalAccept,
  ModalButtonsRow,
  ModalColor,
  ModalColors,
  ModalContainer,
  ModalDecline,
  ModalDisabled,
  ModalInput,
  ModalLabel,
} from "../style";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newHabit } from "../../redux/slices/habitSlice";
import { showNewHabitModal } from "../../redux/slices/modalSlice";
import { colors, habitUrl } from "../../constants/constants";

const NewHabitModal = () => {
  const [areaText, setAreaText] = useState("");
  const [out, setOut] = useState(false);

  const [c, setC] = useState("#ffffff");

  const user = useSelector((store) => store.user);
  const dispatch = useDispatch();

  const closeIt = () => {
    setOut(true);
    setTimeout(() => {
      dispatch(showNewHabitModal(false));
    }, 140);
  };

  const addHabit = async () => {
    const response = await fetch(`${habitUrl}/${user.id}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ title: areaText, color: c }),
    });
    const res = await response.json();
    dispatch(newHabit(res));
  };

  return (
    <Background1 out={out}>
      <ModalContainer out={out}>
        <ModalLabel>{"Добавьте новую привычку 😉"}</ModalLabel>
        <ModalInput
          color={c}
          value={areaText}
          maxLength={"50"}
          onChange={(e) => setAreaText(e.target.value)}
        ></ModalInput>
        <ModalColors>
          {colors.map((el) => {
            return (
              <ModalColor
                color={el}
                selected={el === c}
                onClick={() => {
                  setC((prev) => (prev === el ? "#ffffff" : el));
                }}
              ></ModalColor>
            );
          })}
        </ModalColors>
        <ModalButtonsRow>
          {areaText ? (
            <ModalAccept
              onClick={() => {
                addHabit();
                closeIt();
              }}
            >
              {"добавить"}
            </ModalAccept>
          ) : (
            <ModalDisabled>{"добавить"}</ModalDisabled>
          )}
          <ModalDecline onClick={() => closeIt()}>{"отмена"}</ModalDecline>
        </ModalButtonsRow>
      </ModalContainer>
    </Background1>
  );
};

export default NewHabitModal;
