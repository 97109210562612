import styled from "styled-components";


export const HeaderMainPage = styled.div`
    background-color: #9f7dc0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid black;
    height: 10vh;
    width: 100%;
    position:fixed;
    top:0px;
    z-index: 10;
`
//Ограничит максимальной величиной экрана
export const BodyMainPage = styled.div`
    background-color: #54ebe8;
    display: flex;
    justify-content: space-around;
    /* min-height: 50vh; */
    flex-direction: row;
    
`
export const FooterMainPage = styled.div`
    background-color: #4ebef6;
    display: flex;
    justify-content: center;
    height: 20vh;
`

export const MenuBar = styled.div`
    visibility: hidden;
    background: url(${props=>props.url});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #7b717b;
    display: flex;
    justify-content: center;
    height: 6vh;
    width: 7vh;
    border-radius: 8px;
    border: 2px solid #d3ec46;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 20px;
    margin-left: 10px;
    opacity: 0.5;
    &:hover{
        background-color: #2bb197;
        opacity: 1;
        cursor: pointer;
    }
`

export const WelcomeBlock = styled.div`
    display: flex;
    justify-content: center;
    width: 30%;
    aspect-ratio: 3/4;
    /* margin-left: 100px; */
    background-image: url(${props => props.welcomeImage});
    background-size: cover;
    background-repeat: no-repeat;
`

export const EnterButton = styled.div`
    background-image: url(${props => props.enter});
    background-position: center;
    background-repeat: no-repeat;
    background-color: #7b717b;
    display: flex;
    justify-content: center;
    height: 6vh;
    width: 8vh;
    margin: 15px;
    border-radius: 8px;
    border: 2px solid;
    border-color: #d3ec46;
    opacity: 0.5;
    &:hover{
        opacity: 1;
        background-color: #2bb197;
        cursor: pointer;
    }
`

export const MainBlock = styled.div`
    display: flex;
    justify-content: center ;
    flex-direction: column;
    /* position:fixed; */
    margin-top: 10vh;
    width: ${props => props.width};
    height: ${props => props.height};
`

export const DemoTracker = styled.div`
    background-color: #90c3f0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    height: 30vh;
    background-image: url(${props => props.demo});
`
export const ConnectButton = styled.div`
    visibility: hidden;
    display: flex;
    background-color: red;
    justify-content: center;
    border-radius: 30px;
    align-self: center;
    align-items: center;
    width: 300px;
    height: 50px;
    font-size: 24px;
    margin-top: auto;
    margin-bottom: 10vh;
    border: 5px solid black;
    box-sizing: border-box;
    &:hover{
        cursor: pointer;
        background-color: #2bb197;
    }

`
export const WelcomeTextBlock = styled.div`
    background-color: #f0e0a8;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 18px;    
    width: 70%;
    font-family: Jost, sans-serif;
    font-size: 24px;
`
// 89001418217 - Иван

export const TestTreckerButton = styled.div`
    visibility: hidden;
    display: flex;
    background-color: red;
    justify-content: center;
    border-radius: 30px;
    align-self: center;
    align-items: center;
    width: 300px;
    height: 50px;
    font-size: 24px;
    margin-top: auto;
    margin-bottom: 1vh;
    &:hover{
        cursor: pointer;
        background-color: #2bb197;
    }
`
export const InfoFooterBlock = styled.div`
    display: flex;
    justify-content: start;
    background-color: #565632;
    width: 34.3%;
    flex-direction: column;
    text-align: center;
`

export const ModalWindow = styled.div`
    background: url(${props =>props.foxImage});
    background-size: cover;
    background-position: center;
    visibility: ${props=>props.active ? "visible":"hidden"};
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    z-index: 10;
    border-radius: 30px;
    align-items: center;
    position: fixed;
    min-height: 400px;
    min-width: 600px;
    padding: 30px;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* pointer-events: none; */
    border: 2px solid black;
    font-family: Jost, sans-serif;
`

export const GradientBlock = styled.div`
    right: calc(30% - 90px);
    width: 100px;
    position: absolute;
    height: 100%;
    z-index: 5;
    background: linear-gradient(to right, #f0e0a8 30%, transparent );
`

export const CloseButton = styled.div`
    display: flex;
    background-color: #56ff94;
    justify-content: center;
    height: 45px;
    width: 45px;
    border-radius: 20px;
    box-sizing: border-box;
    color: #2e5948;
    position: fixed;
    font-size: 50px;
    align-items: center;
    top:0%;
    right: 0px;
    padding-bottom: 9px;
    pointer-events: all;
    border: 3px solid black;
    &:hover{
        cursor: pointer;
        background-color: #4ff3a7;
    }
`
export const InputZone = styled.textarea`
    color: black;
    height: 45px;
    font-size: 17px;
    resize: none;
    text-align: center;
    box-sizing: border-box;
    border: 2px solid black;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
`

export const RegButton = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: #8821e8;
    border-radius: 10px;
    font-size: 30px;
    height: 45px;
    width: 100px;
    box-sizing: border-box;
    margin-top: 30px;
    &:hover {
        background-color: #44b970;
        cursor:pointer;
    }
`