import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newHabit: false,
  removeHabit: false,
  removingIndex: null,
  removingHabit: null,
  motivationSpeech: false,
  tutor: false,
  clicked: [],
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showNewHabitModal: (state, action) => {
      state.newHabit = action.payload;
    },
    showRemoveHabitModal: (state, action) => {
      state.removeHabit = action.payload;
    },
    setRemovingIndex: (state, action) => {
      state.removingIndex = action.payload;
    },
    setRemovingHabit: (state, action) => {
      state.removingHabit = action.payload;
    },
    setShowMotivation: (state, action) => {
      state.motivationSpeech = action.payload;
    },
    setClicked: (state, action) => {
      state.clicked = action.payload;
    },
    showTutor: (state, action) => {
      state.tutor = action.payload;
    },
  },
});

export const {
  showNewHabitModal,
  showRemoveHabitModal,
  setRemovingIndex,
  setRemovingHabit,
  setShowMotivation,
  setClicked,
  showTutor,
} = modalSlice.actions;

export default modalSlice.reducer;
