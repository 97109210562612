import styled, { css, keyframes } from "styled-components";
import { styles } from "../../constants/styleValues";

export const HabitListDeskGrandContainer = styled.div`
  ${styles.flexRow}
  justify-content: flex-end;
  width: 50%;
`;

export const HabitListDeskContainer = styled.div`
  ${styles.flexCol}
  justify-content: flex-start;
  width: 96%;
  max-width: 660px;
  /* padding: 10px 0; */
  aspect-ratio: 1 / 1.1;
  border-radius: min(2vw, 20px);
  overflow: hidden;
  background-color: antiquewhite;
`;

export const HabitListDeskHeaderContainer = styled.div`
  ${styles.flexRow}
  width: 100%;
`;

export const DatePickerDesktopGrand = styled.div`
  ${styles.flexCol}
  width: 100%;
  /* background-color: blueviolet; */
  padding: min(1.5vw, 15px) 0;
  gap: min(0.5vw, 5px);
`;

export const DatePickerDesktopRow = styled.div`
  ${styles.flexRow}
  justify-content: flex-start;
  width: 90%;
`;

export const DatePickerDesktopDateBox = styled.div`
  ${styles.flexRow}
  width: calc(100% / 11);
`;

export const DatePickerDesktopDate = styled.div`
  ${styles.flexRow}
  width: 90%;
  overflow: hidden;
  aspect-ratio: 1 / 1;
  border-radius: min(0.5vw, 5px);
  /* border: min(0.1vw, 3px) solid transparent; */
  font-size: min(2vw, 20px);
  cursor: pointer;
  background-color: rgb(255, 216, 129);
  color: #6c6c6c;
  border: 1px solid #88843b;
  ${(props) =>
    props.selected
      ? `
    transform: scale(1.2);
    border: min(0.1vw, 3px) solid black;
    background-color: rgb(255, 252, 193);
    color: black;
  `
      : !props.disabled
      ? `
    &:hover {
      filter: brightness(1.1);
    }
  `
      : `
      cursor: default;
      filter: grayscale(1);
      color: rgb(179 179 179);
      border-color: rgb(179 179 179);
      `}
`;

export const HabitCheckerGrand = styled.div`
  ${styles.flexRow}
  width: 90%;
  margin-bottom: min(0.5vw, 5px);
  overflow: hidden;
  /* background-color: #ff512a; */
  /* border-radius: 0 0 min(2vw, 20px) min(2vw, 20px); */
`;

export const HabitCheckerScroll = styled.div`
  ${styles.flexCol}
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: scroll;
  width: 100%;
  gap: min(0.7vw, 7px);
  cursor: pointer;
  ::-webkit-scrollbar {
    background-color: rgb(0, 0, 0, 0.1);
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(218, 151, 64);
    border-radius: 10px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(255, 178, 77);
    border-radius: 10px;
    background-clip: content-box;
  }
`;

export const HabitCheckerRow = styled.div`
  cursor: default;
  background-color: ${(props) =>
    props.complete
      ? props.status
        ? "rgb(176 217 182)"
        : "rgb(244 205 189)"
      : "rgb(212 212 212)"};
  ${styles.flexRow}
  width: 98%;
  border-radius: min(2vw, 20px);
`;

export const HabitCheckerLeft = styled.div`
  ${styles.flexRow}
  border-radius: min(2vw, 20px);
  width: 80%;
  flex-shrink: 0;
  aspect-ratio: 6 / 1;
  justify-content: flex-start;
  align-self: flex-start;
  overflow: hidden;
  font-size: min(1.2vw, 20px);
  padding: 0 min(1.5vw, 15px);
`;

export const HabitCheckerRight = styled.div`
  ${styles.flexRow}
  /* justify-content: flex-start; */
  width: 20%;
  padding: min(0.7vw, 12px) 0;
`;

export const HabitCheckerRightVerify = styled.div`
  line-height: min(3vw, 40px);
  text-align: ${(props) => (props.status ? "left" : "right")};
  padding: 0 min(1.6vw, 16px);
  width: 70%;
  font-size: min(3vw, 40px);
  overflow: hidden;
  border-radius: 100px;
  aspect-ratio: 2 / 1;
  background-color: ${(props) =>
    props.status ? "rgb(94 152 150)" : "rgb(219 112 112)"};
  /* border-radius: 100%; */
  cursor: pointer;
`;

const sliderToRight = keyframes`
  from {
    right: 50%;
  }
  to {
    right: 0%;
  }
`;

const sliderToLeft = keyframes`
  from {
    left: 50%;
  }
  to {
    left: 0;
  }
`;

export const HabitCheckerSlider = styled.div`
  position: absolute;
  background-color: white;
  /* text-align: center; */
  ${styles.flexRow}
  border-radius: 100px;
  width: 50%;
  aspect-ratio: 1 / 1;
  transform: scale(0.9);
  overflow: hidden;
  top: 0;
  ${(props) =>
    props.status
      ? `
      right: 0;
      font-size: 60%;
      color: #57837c;
      `
      : `
      left: 0;
      font-size: 70%;
      color: #b84e4e;
    `}
  animation: ${(props) =>
    props.clicked
      ? props.status
        ? sliderToRight
        : sliderToLeft
      : "none"} 0.1337s;
`;

export const HabitCHeckerStatus = styled.div`
  ${styles.flexRow}
  width: 50%;
  font-size: min(2.5vw, 25px);
  overflow: hidden;
  color: ${(props) =>
    props.complete ? (props.status ? "#57837c" : "#b84e4e") : "#6a6a6a"};
`;

export const ChangerGrand = styled.div`
  ${styles.flexRow}
  justify-content: space-between;
  width: 90%;
  margin-top: min(0.5vw, 5px);
  margin-bottom: min(2vw, 15px);
`;

export const ChangerInitButton = styled.div`
  ${styles.button}
  ${styles.dangerButton}
  width: 87%;
`;

export const AddHabitButton = styled(ChangerInitButton)`
  width: 12%;
  ${styles.acceptButton}/* width: 10%; */
  /* overflow: hidden; */
`;

export const ChangerSaveButton = styled(ChangerInitButton)`
  /* width: 49%;
  color: rgb(36 128 64);
  background-color: rgb(115 223 128); */
  ${styles.button}
  width: 49%;
  ${styles.acceptButton}
`;

export const ChangerCancaleButton = styled(ChangerSaveButton)`
  /* color: #7d4230;
  background-color: #da9e80; */
  ${styles.declineButton}
`;

// background-color: rgb(255 253 252);
//     /* font-size: min(3vw, 30px); */
//     color: #88703e;
//     border: 2px solid #d8cd5

export const HabitListHeaderButton = styled.div`
  ${styles.flexRow}
  width: 100%;
  padding: min(1vw, 10px) min(3vw, 30px);
  font-size: min(2vw, 20px);
  color: #79654a;
  background-color: #ffebc4;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
  }
  &:first-child {
    border-top-left-radius: min(2vw, 20px);
  }
  &:last-child {
    border-top-right-radius: min(2vw, 20px);
  }
  /* ${(props) =>
    props.selected
      ? `
      background-color: rgb(255 253 252);
      color: #88703e;
      border: 2px solid rgb(184 180 125);
      border-bottom: none;
    `
      : `
      &:hover {
        filter: brightness(1.05);
      }
    `} */
`;

export const HabitListDeskHabits = styled.div``;

export const HabitListNoHabits = styled(HabitCheckerLeft)`
  width: 100%;
  aspect-ratio: 10 / 1;
`;

//background-color: rgb(148 209 178); rgb(94 152 150); background-color: #f3b1b1;
