import { useDispatch, useSelector } from "react-redux";
import {
  Background,
  Background1,
  ModalAccept,
  ModalButtonsRow,
  ModalContainer,
  ModalDecline,
  ModalLabel,
} from "../style";
import { removeHabit } from "../../redux/slices/habitSlice";
import { showRemoveHabitModal } from "../../redux/slices/modalSlice";
import { useState } from "react";

const RemoveHabit = () => {
  const [out, setOut] = useState(false);

  const dispatch = useDispatch();
  const modals = useSelector((store) => store.modals);

  const closeIt = () => {
    setOut(true);
    setTimeout(() => {
      dispatch(showRemoveHabitModal(false));
    }, 140);
  };

  return (
    <Background1 out={out}>
      <ModalContainer out={out}>
        <ModalLabel>
          {`Вы уверены что хотите удалить привычку\n`}
          <span style={{ fontStyle: "italic" }}>
            {`«${modals.removingHabit}»`}
          </span>
          {"?"}
        </ModalLabel>
        <ModalButtonsRow>
          <ModalAccept
            onClick={() => {
              dispatch(removeHabit(modals.removingIndex));
              closeIt();
            }}
          >
            {"да"}
          </ModalAccept>
          <ModalDecline onClick={() => closeIt()}>{"нет"}</ModalDecline>
        </ModalButtonsRow>
      </ModalContainer>
    </Background1>
  );
};

export default RemoveHabit;
