import { useState } from "react";
import HabitDesktopHeader from "../HabitDesktopHeader/HabitDesktopHeader";
import HabitImprovedDesktop from "../HabitImprovedDesktop/HabitImprovedDesktop";
import {
  Background,
  BadPageComment,
  BearContainer,
  KekW,
} from "../HabitImprovedDesktop/style";
import { HabitDesktopCalendarAndOtherGrand } from "../HabitTracker/style";

const FourZeroFour = () => {
  const [vh, setVh] = useState(window.innerHeight);

  return (
    <KekW style={{ top: 0, height: `${vh}px` }}>
      <Background></Background>
      <HabitDesktopCalendarAndOtherGrand
        style={{ height: `${vh}px`, flexDirection: "column" }}
      >
        <BearContainer></BearContainer>
        <BadPageComment>{"Упс...  Доступ к странице ограничен"}</BadPageComment>
      </HabitDesktopCalendarAndOtherGrand>
    </KekW>
  );
};

export default FourZeroFour;
