import { useState } from "react";
import {
  Background,
  BadPageComment,
  BearContainer,
  KekW,
} from "../HabitImprovedDesktop/style";
import { HabitDesktopCalendarAndOtherGrand } from "../HabitTracker/style";
import { LoadingBar, LoadingBarBox } from "../MotivationModal/style";
import { isMobile } from "react-device-detect";

const DownloadScreen = () => {
  const [vh, setVh] = useState(window.innerHeight);

  return (
    <KekW style={{ top: 0, height: `${vh}px`, zIndex: 100 }}>
      <Background></Background>
      <HabitDesktopCalendarAndOtherGrand
        style={{ height: `${vh}px`, flexDirection: "column" }}
      >
        <BearContainer isMobile={isMobile}></BearContainer>
        <LoadingBarBox isMobile={isMobile}>
          <LoadingBar delay={0} isMobile={isMobile}></LoadingBar>

          <LoadingBar delay={0.2} isMobile={isMobile}></LoadingBar>

          <LoadingBar delay={0.3} isMobile={isMobile}></LoadingBar>

          <LoadingBar delay={0.4} isMobile={isMobile}></LoadingBar>
        </LoadingBarBox>
        {/* <BadPageComment>{"Упс...  Доступ к странице ограничен"}</BadPageComment> */}
      </HabitDesktopCalendarAndOtherGrand>
    </KekW>
  );
};

export default DownloadScreen;
