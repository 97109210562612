import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { dateInit } from "../../redux/slices/dateSlice";
import {
  habitCheckUrl,
  habitUrl,
  localTest,
  userUrl,
} from "../../constants/constants";
import { login } from "../../redux/slices/userSlice";
import { initHabits } from "../../redux/slices/habitSlice";

const Init = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localTest) {
      const fetchUser = async () => {
        const response = await fetch(`${userUrl}/login/1&1`);
        const user = await response.json();
        const regDate = new Date(user.createdAt);
        user.utterDate = [
          regDate.getMonth() + 1,
          regDate.getFullYear(),
          regDate.getDate(),
        ];

        const response2 = await fetch(`${habitUrl}/${user.id}`);
        const habits = await response2.json();

        const response3 = await fetch(`${habitCheckUrl}/${user.id}`);
        const habitChecks = await response3.json();

        dispatch(
          login({
            id: user.id,
            name: user.name,
            login: user.login,
            role: user.role,
            utterDate: user.utterDate,
          })
        );

        dispatch(dateInit([0, 2023, 1]));
        dispatch(initHabits({ habits, habitChecks }));
      };

      fetchUser();
    }
  }, []);
  return <></>;
};

export default Init;
