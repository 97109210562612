import styled, { css, keyframes } from "styled-components";
import { modals, styles } from "../constants/styleValues";

const backIn = keyframes`
  ${modals.backIn}
`;

const backOut = keyframes`
  ${modals.backOut}
`;

const modalIn = keyframes`
  ${modals.modalIn}
`;

const modalOut = keyframes`
  ${modals.modalOut}
`;

export const Background1 = styled.div`
  ${modals.background}
  animation: ${(props) => (!props.out ? backIn : backOut)} .15s;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 20;
`;

export const ModalContainer = styled.div`
  ${modals.defaultModal}
  ${styles.flexCol} /* animation: ${(props) =>
    !props.out ? modalIn : modalOut} .17s linear; */
  width: 700px;
  max-width: 90%;
  font-size: ${(props) =>
    props.isMobile ? "min(60px, 3vw)" : "min(30px, 1.5vw)"};
`;

export const ModalLabel = styled.div`
  white-space: pre-wrap;
  width: 100%;
  box-sizing: border-box;
  font-size: 20px;
  padding: 19px 32px;
  text-align: center;
  color: rgb(132 117 48);
  font-weight: 500;
`;

export const ModalColors = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: min(20px);
  gap: min(8px);
  cursor: pointer;
`;

export const ModalColor = styled.div`
  width: 13%;
  height: min(30px);
  border-radius: min(30px);
  background-color: ${(props) => props.color};
  box-sizing: content-box;
  border: ${(props) => (props.selected ? "3px solid grey" : "")};
`;

export const ModalInput = styled.textarea`
  box-sizing: border-box;
  border-radius: min(6px);
  width: 90%;
  height: min(70px);
  font-size: min(20px);
  padding: 10px 20px;
  resize: none;
  margin-bottom: min(20px);
  background-color: ${(props) => props.color}99;
  &:focus {
    outline: none;
    border: 2px solid rgb(132 117 48);
  }
`;

export const ModalButtonsRow = styled.div`
  ${styles.flexRow}
  width: 90%;
  gap: min(10px);
  margin-bottom: min(20px);
`;

export const ModalAccept = styled.div`
  ${styles.button}
  ${styles.acceptButton}
  height: min(44px);
  line-height: min(44px);
  ${(props) =>
    props.isMobile &&
    css`
      height: min(70px, 3.5vw);
      line-height: min(70px, 3.5vw);
    `}
`;

export const ModalDanger = styled.div`
  ${styles.button}
  ${styles.dangerButton}
  height: min(44px);
  line-height: min(44px);
  ${(props) =>
    props.isMobile &&
    css`
      height: min(70px, 3.5vw);
      line-height: min(70px, 3.5vw);
    `}
`;

export const ModalDecline = styled.div`
  ${styles.button}
  ${styles.declineButton}
  height: min(44px);
  line-height: min(44px);
  ${(props) =>
    props.isMobile &&
    css`
      height: min(70px, 3.5vw);
      line-height: min(70px, 3.5vw);
    `}
`;

export const ModalDisabled = styled.div`
  ${styles.button}
  ${styles.disabledButton}
  height: min(44px);
  line-height: min(44px);
  ${(props) =>
    props.isMobile &&
    css`
      height: min(70px, 3.5vw);
      line-height: min(70px, 3.5vw);
    `}
`;
