import { Route, Routes } from "react-router";
import { router } from "./routes/Routes";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import Modals from "./modals/Modals";
import Init from "./components/Init/Init";

function App() {
  return (
    <Provider store={store}>
      <Modals />
      <Routes>
        {router.any.map((el, i) => (
          <Route key={i} path={el.path} element={el.component} />
        ))}
      </Routes>
    </Provider>
  );
}

export default App;
