import { configureStore } from "@reduxjs/toolkit";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import deviceSlice from "./slices/deviceSlice";
import habitSlice from "./slices/habitSlice";
import modalSlice from "./slices/modalSlice";
import dateSlice from "./slices/dateSlice";
import userSlice from "./slices/userSlice";

export const store = configureStore({
  reducer: {
    habits: habitSlice,
    modals: modalSlice,
    date: dateSlice,
    user: userSlice,
    device: deviceSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
