import { useDispatch, useSelector } from "react-redux";
import {
  AddHabitButton,
  ChangerCancaleButton,
  ChangerGrand,
  ChangerInitButton,
  ChangerSaveButton,
  HabitCHeckerStatus,
  HabitCheckerGrand,
  HabitCheckerLeft,
  HabitCheckerRight,
  HabitCheckerRightVerify,
  HabitCheckerRow,
  HabitCheckerScroll,
  HabitCheckerSlider,
  HabitListDeskContainer,
  HabitListDeskGrandContainer,
  HabitListDeskHeaderContainer,
  HabitListHeaderButton,
  HabitListNoHabits,
} from "./style";
import { useRef, useState } from "react";
import { changeSelectedDay } from "../../redux/slices/dateSlice";
import { useEffect } from "react";
import { showNewHabitModal } from "../../redux/slices/modalSlice";
const dateBoxArray = new Array(3).fill();

const HabitListDesktop = () => {
  const habits = useSelector((store) => store.habits);
  const date = useSelector((store) => store.date);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);
  const [changeMod, setChangeMod] = useState(false);
  const [check, setCheck] = useState({});

  const [sliderClicked, setSliderClicked] = useState(
    new Array(habits.habits.length).fill(false)
  );
  const [testArr, setTestArr] = useState(
    new Array(habits.habits.length).fill(false)
  );

  const habitsRef = useRef();

  const headerButtonClick = (index) => {
    setSelected(index);
  };

  const changerClick = () => {
    const checks = habits.habitChecks[date.dateStr];
    if (checks) {
      setCheck(checks);
    } else {
      const h = habits.habits;
      const res = {};
      for (let i = 0; i < h.length; i++) {
        res[h.id] = 0;
      }
      setCheck(res);
    }
    setChangeMod((prev) => !prev);
  };

  const sliderClick = (id, index) => {
    setCheck((prev) => {
      const temp = { ...prev };
      temp[id] = temp[id] ? 0 : 1;
      return temp;
    });
    // setTestArr((prev) => prev.map((el, i) => (i === index ? !el : el)));
    setSliderClicked((prev) => prev.map((el, i) => (i === index ? true : el)));
  };

  const dayButtonClick = (index) => {
    dispatch(changeSelectedDay({ date: index }));
  };

  useEffect(() => {
    setSliderClicked(new Array(20).fill(false));
  }, [selected, changeMod]);

  return (
    <HabitListDeskGrandContainer>
      <HabitListDeskContainer>
        <HabitListDeskHeaderContainer>
          {/* {["Активность", "Редактор"].map((el, i) => {
            return ( */}
          <HabitListHeaderButton>{"Активность"}</HabitListHeaderButton>
          {/* );
          })} */}
        </HabitListDeskHeaderContainer>

        <>
          {/* <DatePickerDesktopGrand>
            {dateBoxArray.map((_, j) => {
              return (
                <DatePickerDesktopRow key={j}>
                  {new Array(j === 2 ? 11 - (33 - date.daysArray.length) : 11)
                    .fill()
                    .map((_, i) => {
                      const day = j * 11 + i + 1;
                      const isDisabled =
                        (date.isCurrent && date.day < day) ||
                        (date.isLast && date.firstDay > day);
                      return (
                        <DatePickerDesktopDateBox>
                          <DatePickerDesktopDate
                            onClick={() => !isDisabled && dayButtonClick(day)}
                            selected={date.selectedDay === day}
                            disabled={isDisabled}
                          >
                            {j * 11 + i + 1}
                          </DatePickerDesktopDate>
                        </DatePickerDesktopDateBox>
                      );
                    })}
                </DatePickerDesktopRow>
              );
            })}
          </DatePickerDesktopGrand> */}
          <HabitCheckerGrand>
            <HabitCheckerScroll>
              {habits.habits.length > 0 ? (
                habits.habits.map((el, i) => {
                  return (
                    <HabitCheckerRow
                      complete={date.isDateComplete || changeMod}
                      status={check[el.id]}
                    >
                      <HabitCheckerLeft>{el.title}</HabitCheckerLeft>

                      <HabitCheckerRight>
                        {changeMod ? (
                          <HabitCheckerRightVerify
                            status={check[el.id]}
                            onClick={() => sliderClick(el.id, i)}
                          >
                            <HabitCheckerSlider
                              status={check[el.id]}
                              clicked={sliderClicked[i]}
                            >
                              {check[el.id] ? "✓" : "×"}
                            </HabitCheckerSlider>
                          </HabitCheckerRightVerify>
                        ) : (
                          <HabitCHeckerStatus
                            complete={date.isDateComplete}
                            status={check[el.id]}
                          >
                            {date.isDateComplete
                              ? check[el.id]
                                ? "✓"
                                : "×"
                              : "?"}
                          </HabitCHeckerStatus>
                        )}
                      </HabitCheckerRight>
                    </HabitCheckerRow>
                  );
                })
              ) : (
                <HabitListNoHabits>
                  {"У Вас пока нет ни одной привычки 😦"}
                </HabitListNoHabits>
              )}
            </HabitCheckerScroll>
          </HabitCheckerGrand>
          <ChangerGrand>
            {changeMod ? (
              <>
                <ChangerSaveButton onClick={() => changerClick()}>
                  {"Сохранить"}
                </ChangerSaveButton>
                <ChangerCancaleButton onClick={() => changerClick()}>
                  {"Отмена"}
                </ChangerCancaleButton>
              </>
            ) : (
              <>
                <ChangerInitButton onClick={() => changerClick()}>
                  {"Редактировать"}
                </ChangerInitButton>
                <AddHabitButton
                  onClick={() => dispatch(showNewHabitModal(true))}
                >
                  {"+"}
                </AddHabitButton>
              </>
            )}
          </ChangerGrand>
        </>
      </HabitListDeskContainer>
    </HabitListDeskGrandContainer>
  );
};

export default HabitListDesktop;
