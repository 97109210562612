import { useDispatch, useSelector } from "react-redux";
import {
  AddHabitI,
  Background,
  Background2,
  CheckButtonBox,
  DayS,
  DeletModal,
  DeleteIt,
  DeleteLabel,
  DiaryQ,
  DoneBack,
  DoneButton,
  FailBack,
  FailButton,
  HabitChooseBox,
  HabitIActivityContainer,
  HabitIActivityDay,
  HabitIActivityDefault,
  HabitIActivityFake,
  HabitIActivityRow,
  HabitIFakeDay,
  HabitIGrandContainer,
  HabitIHabitName,
  HabitIHabitNameHeader,
  HabitIInput,
  HabitIRedactor,
  HabitMobileRight,
  LabelHui,
  NullBack,
  NullButton,
} from "./style";
import {
  editHabit,
  habitCheck,
  perfectDay,
  removeHabit,
  setStat,
} from "../../redux/slices/habitSlice";
import {
  colors,
  colors2,
  habitCheckUrl,
  habitUrl,
  qs,
} from "../../constants/constants";
import {
  setClicked,
  setShowMotivation,
  showNewHabitModal,
} from "../../redux/slices/modalSlice";
import { useState } from "react";
import {
  Background1,
  ModalAccept,
  ModalButtonsRow,
  ModalColor,
  ModalColors,
  ModalDanger,
  ModalDecline,
  ModalLabel,
} from "../../modals/style";
import { useRef } from "react";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { isPerfectLine } from "../../functions/functions";
import { set } from "date-fns";
let dRes, sChange;
let clicked = false;

const HabitImprovedDesktop = ({ type }) => {
  const date = useSelector((store) => store.date);
  const habits = useSelector((store) => store.habits);
  const user = useSelector((store) => store.user);
  const modals = useSelector((store) => store.modals);

  const [p, setP] = useState([]);

  const [c, setC] = useState("#ffffff");
  const [changeId, setChangeId] = useState(null);

  const [showDel, setShowDel] = useState(false);

  const [w, setW] = useState(0);
  const [h, setH] = useState(0);

  const [wH, setWH] = useState(0);
  const [hH, setHH] = useState(0);

  const [out, setOut] = useState(false);

  const [kekArray, setKekArray] = useState(new Array(101).fill());

  const [pos, setPos] = useState([-9999, -9999]);
  const [idd, setId] = useState(null);
  const [ij, setIj] = useState([-1, -1]);
  const [oW, setOW] = useState(0);

  const [val, setVal] = useState("");

  const rRef = useRef();
  const kekRef = useRef();
  const huiRef = useRef();
  const daysRef = useRef();
  const checksRef = useRef([]);

  const pRefs = useRef([]);

  const dispatch = useDispatch();

  const scrollHandler = (e) => {
    try {
      if (e.target !== daysRef.current) {
        daysRef.current.scrollLeft = e.target.scrollLeft;
      }
      for (let i = 0; i < checksRef.current.length; i++) {
        checksRef.current[i].scrollLeft = e.target.scrollLeft;
      }
    } catch (e) {}
  };

  const checkClickHandler = async (id, status, date) => {
    fetch(`${habitCheckUrl}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: user.id,
        habitId: id,
        checked: status,
        date,
      }),
    });

    // dRes = dateRes;
    // sChange = statusChange;
    setOut(true);
    setTimeout(() => {
      dispatch(setClicked([]));
      setOut(false);
    }, 140);

    dispatch(
      habitCheck({
        id: id,
        date,
        status,
      })
    );
  };

  useEffect(() => {
    if (changeId !== null) {
      rRef.current.focus();
    }
  }, [changeId]);

  useEffect(() => {
    if (isMobile && daysRef.current && date.daysArray && checksRef.current) {
      const wdth = daysRef.current.scrollWidth / date.daysArray.length;
      daysRef.current.scrollLeft = (date.day - 1) * wdth - 1;
    }
  }, [daysRef, checksRef, date, habits]);

  useEffect(() => {
    if (huiRef.current) {
      setWH(huiRef.current.offsetWidth);
      setHH(huiRef.current.offsetHeight);
    }
  }, [huiRef]);

  useEffect(() => {
    if (pRefs.current) {
      const kek = pRefs.current
        .map((el, i) => ({
          x: el ? el.offsetLeft + Math.ceil(wH / 2) : null,
          y: el
            ? el.offsetTop + (100 - habits.stat[i][qs.length - 1]) * hH + hH * 2
            : null,
        }))
        .filter((el) => el.x);
      setP(kek);
    }
  }, [pRefs, wH, hH, date, habits]);

  useEffect(() => {
    // setKekArray(new Array(101).fill(Math.random()));
    setP([]);
  }, [date]);

  useEffect(() => {
    if (kekRef.current) {
      setW(kekRef.current.offsetWidth);
      setH(kekRef.current.offsetHeight);
    }
  }, [kekRef]);

  useEffect(() => {
    if (!type) {
      const kek = async () => {
        const f = await fetch(
          "http://89.108.88.96:3001/Diary/get-diary-by-user",
          {
            method: "put",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ tele_id: user.tele_id }),
          }
        );

        const resp = await f.json();

        const [day2, m2, y2] = date.dateStr.split(".");

        const ans = {};

        for (let i = 0; i < resp.length; i++) {
          const d = new Date(resp[i].createdAt);
          const [day, m, y] = [
            d.getDate(),
            `${d.getMonth()}`,
            `${d.getFullYear()}`.slice(2, 4),
          ];
          if (m2 === m && y2 === y) {
            if (!ans[day]) {
              ans[day] = [
                ...JSON.parse(
                  resp[i].answers.replace(/'/gi, '"').replace(/ /gi, "")
                ),
                resp[i].Effectivness + "%",
                resp[i].Effectivness,
              ];
            }
          }
        }

        dispatch(setStat(ans));

        // setStat(stat);
      };

      kek();
    }
  }, [type]);

  useEffect(() => {
    if (!habits.isPerfectDay && dRes) {
      dRes = date.dateNowStr;
      if (isPerfectLine(habits.ids, habits.habitChecks, dRes)) {
        dispatch(perfectDay(true));
        setTimeout(() => dispatch(setShowMotivation(true)), 237);
      }
    }
  }, [habits.habitChecks]);

  useEffect(() => {}, []);

  return (
    <>
      {isMobile &&
        modals.clicked[0] === ij[0] &&
        modals.clicked[1] === ij[1] && (
          <>
            <HabitChooseBox
              out={out}
              isMobile={true}
              style={{
                position: "fixed",
                top: `${pos[0] - oW / 8}px`,
                right: `${window.innerWidth - pos[1] + 1}px`,
                zIndex: 21,
              }}
            >
              <CheckButtonBox
                isMobile={true}
                onClick={() => checkClickHandler(idd, "done", dRes)}
              >
                <DoneBack isMobile={true}></DoneBack>
                <DoneButton isMobile={true}></DoneButton>
              </CheckButtonBox>

              <CheckButtonBox
                isMobile={true}
                onClick={() => checkClickHandler(idd, "fail", dRes)}
              >
                <FailBack isMobile={true}></FailBack>
                <FailButton isMobile={true}></FailButton>
              </CheckButtonBox>

              <CheckButtonBox
                isMobile={true}
                onClick={() => checkClickHandler(idd, null, dRes)}
              >
                <NullBack isMobile={true}></NullBack>
                <NullButton isMobile={true}></NullButton>
              </CheckButtonBox>
            </HabitChooseBox>
          </>
        )}
      {modals.clicked[0] !== undefined && modals.clicked[1] !== undefined && (
        <>
          <Background1
            out={out}
            onClick={() => {
              setOut(true);
              setTimeout(() => {
                dispatch(setClicked([]));
                setOut(false);
              }, 140);
            }}
          ></Background1>
        </>
      )}
      {changeId && (
        <>
          <Background1
            onClick={() => {
              setShowDel(false);
              setChangeId(null);
            }}
            style={{ zIndex: 10 }}
          ></Background1>
        </>
      )}
      {type ? (
        isMobile ? (
          <>
            <HabitIGrandContainer
              style={{
                marginBottom: "max(-60px, -3vw)",
                backgroundColor: "transparent",
                boxShadow: "none",
                marginTop: isMobile ? "min(50px, 2.5vw)" : "",
              }}
            >
              <HabitIActivityContainer>
                <HabitIActivityRow
                  monthWidth={date.daysArray.length}
                  nonHover={true}
                  // style={{ backgroundColor: "yellow" }}
                >
                  {!habits.habits.length && (
                    <AddHabitI
                      isMobile={isMobile}
                      onClick={() => {
                        dispatch(showNewHabitModal(true));
                      }}
                    >
                      {"Добавить привычку"}
                    </AddHabitI>
                  )}
                  {[
                    "Привычка",
                    date.daysArray,
                    // ...new Array(31 - date.daysArray.length).fill("fake"),
                  ].map((elem, i) => {
                    return i && elem !== "fake" ? (
                      <HabitMobileRight
                        onScroll={(e) => scrollHandler(e)}
                        ref={daysRef}
                      >
                        {elem.map((el) => (
                          <HabitIActivityDay
                            first={i === 1}
                            isFuture={el > date.day}
                            isToday={el === date.day}
                            isMobile={isMobile}
                          >
                            {el}
                          </HabitIActivityDay>
                        ))}
                      </HabitMobileRight>
                    ) : elem === "fake" ? (
                      <HabitIFakeDay></HabitIFakeDay>
                    ) : (
                      <HabitIHabitNameHeader isMobile={isMobile}>
                        {elem}
                      </HabitIHabitNameHeader>
                    );
                  })}
                </HabitIActivityRow>
              </HabitIActivityContainer>
            </HabitIGrandContainer>
            <HabitIGrandContainer style={{ marginBottom: "min(300px)" }}>
              <HabitIActivityContainer>
                {habits.habits.map((habit, j) => {
                  return (
                    <HabitIActivityRow monthWidth={date.daysArray.length}>
                      {[habit.title, date.daysArray].map((elem, i) => {
                        // const status = [true, false][Math.floor(Math.random() * 3)
                        return i && elem !== "fake" ? (
                          <HabitMobileRight
                            onScroll={(e) => scrollHandler(e)}
                            ref={(r) => (checksRef.current[j] = r)}
                          >
                            {elem.map((el, k) => {
                              const dateTmp = date.dateStr.split(".");
                              const dateRes = `${el}.${dateTmp[1]}.${dateTmp[2]}`;
                              let status;
                              let ok;
                              try {
                                status = habits?.habitChecks[habit.id][dateRes];
                                ok = habits?.habitChecks[habit.id][dateRes];
                              } catch {
                                status = null;
                                ok = null;
                              }
                              const isFuture = el > date.day;
                              const isToday = el === date.day;
                              return (
                                <HabitIActivityDefault
                                  style={{
                                    zIndex:
                                      modals.clicked[0] === k &&
                                      modals.clicked[1] === j
                                        ? 20
                                        : 0,
                                  }}
                                  isMobile={isMobile}
                                  first={k === 1}
                                  last={j === habits.habits.length - 1}
                                  isFuture={isFuture}
                                  isToday={isToday}
                                  status={status}
                                  ok={ok}
                                  onClick={(e) => {
                                    dRes = dateRes;
                                    if (
                                      (!isFuture || isToday) &&
                                      !modals.clicked.length
                                    ) {
                                      const rect =
                                        e.target.getBoundingClientRect();
                                      setOW(e.target.offsetWidth);
                                      setPos([rect.top, rect.left]);
                                      setId(habit.id);
                                      setIj([k, j]);
                                      dispatch(setClicked([k, j]));
                                    }
                                  }}
                                >
                                  {!isFuture && ok
                                    ? status === "done"
                                      ? "✓"
                                      : "×"
                                    : ""}
                                  {/* {!isToday && !isFuture && (
                  <HabitIActivityCircle
                    status={status}
                  ></HabitIActivityCircle>
                )} */}
                                </HabitIActivityDefault>
                              );
                            })}
                          </HabitMobileRight>
                        ) : elem === "fake" ? (
                          <HabitIActivityFake></HabitIActivityFake>
                        ) : (
                          <>
                            <HabitIHabitName
                              border={true}
                              isMobile={isMobile}
                              last={j === habits.length - 1}
                              style={{
                                backgroundColor:
                                  changeId === habit.id
                                    ? habit.color
                                    : habit.color + "99",
                                zIndex: habit.id === changeId ? 10 : 1,
                              }}
                              onClick={() => {
                                setVal(habit.title);
                                setC(habit.color);
                                setChangeId((prev) =>
                                  prev === habit.id ? null : habit.id
                                );
                              }}
                            >
                              {elem}
                            </HabitIHabitName>
                            {habit.id === changeId && (
                              <>
                                <HabitIRedactor ref={rRef} isMobile={isMobile}>
                                  {showDel && (
                                    <DeletModal>
                                      <DeleteLabel isMobile={isMobile}>
                                        {"Удалить привычку?"}
                                      </DeleteLabel>
                                      <ModalButtonsRow
                                        style={{
                                          marginBottom: "min(40px, 2vw)",
                                          gap: "min(10px, 0.5vw)",
                                        }}
                                      >
                                        <ModalDecline
                                          isMobile={isMobile}
                                          style={{
                                            fontSize: "min(40px, 2vw)",
                                            height: "min(120px, 6vw)",
                                            lineHeight: "min(120px, 6vw)",
                                          }}
                                          onClick={() => {
                                            fetch(`${habitUrl}/${habit.id}`, {
                                              method: "delete",
                                            });

                                            dispatch(removeHabit(habit.id));
                                            setShowDel(false);
                                            setChangeId(null);
                                          }}
                                        >
                                          {"Да"}
                                        </ModalDecline>
                                        <ModalAccept
                                          isMobile={isMobile}
                                          onClick={() => {
                                            setShowDel(false);
                                          }}
                                          style={{
                                            fontSize: "min(40px, 2vw)",
                                            height: "min(120px, 6vw)",
                                            lineHeight: "min(120px, 6vw)",
                                          }}
                                        >
                                          {"Нет"}
                                        </ModalAccept>
                                      </ModalButtonsRow>
                                    </DeletModal>
                                  )}
                                  <DeleteIt
                                    onClick={() => setShowDel(true)}
                                    style={{
                                      width: "min(80px, 4vw)",
                                      height: "min(80px, 4vw)",
                                    }}
                                  ></DeleteIt>
                                  <ModalLabel
                                    style={{
                                      padding: "min(10px, 0.5vw)",
                                      fontSize: "min(50px, 2.5vw)",
                                      marginTop: "min(10px, 0.5vw)",
                                    }}
                                  >
                                    {"Изменить привычку"}
                                  </ModalLabel>
                                  <HabitIInput
                                    maxLength={"50"}
                                    style={{
                                      backgroundColor: c + "99",
                                      height: "min(10vw, 200px)",
                                      fontSize: "min(50px, 2.5vw)",
                                    }}
                                    value={val}
                                    onChange={(e) => setVal(e.target.value)}
                                  ></HabitIInput>
                                  <ModalColors
                                    style={{
                                      gap: "0",
                                      justifyContent: "space-between",
                                      marginBottom: "0",
                                    }}
                                  >
                                    {colors.map((el) => {
                                      return (
                                        <ModalColor
                                          style={{
                                            width: "min(100px, 5vw)",
                                            height: "min(100px, 5vw)",
                                          }}
                                          color={el}
                                          selected={el === c}
                                          onClick={() => {
                                            setC((prev) =>
                                              prev === el ? "#ffffff" : el
                                            );
                                          }}
                                        ></ModalColor>
                                      );
                                    })}
                                  </ModalColors>
                                  <ModalButtonsRow
                                    style={{
                                      marginBottom: "min(40px, 2vw)",
                                      gap: "min(10px, 0.5vw)",
                                    }}
                                  >
                                    <ModalDanger
                                      style={{
                                        fontSize: "min(40px, 2vw)",
                                        height: "min(120px, 6vw)",
                                        lineHeight: "min(120px, 6vw)",
                                      }}
                                      onClick={() => {
                                        fetch(`${habitUrl}`, {
                                          method: "post",
                                          headers: {
                                            "Content-Type": "application/json",
                                          },
                                          body: JSON.stringify({
                                            color: c,
                                            title: val,
                                            id: habit.id,
                                          }),
                                        });

                                        dispatch(
                                          editHabit({
                                            id: habit.id,
                                            title: val,
                                            color: c,
                                          })
                                        );
                                        setShowDel(false);
                                        setChangeId(null);
                                      }}
                                    >
                                      {"изменить"}
                                    </ModalDanger>
                                    <ModalDecline
                                      onClick={() => {
                                        setShowDel(false);
                                        setChangeId(null);
                                      }}
                                      style={{
                                        fontSize: "min(40px, 2vw)",
                                        height: "min(120px, 6vw)",
                                        lineHeight: "min(120px, 6vw)",
                                      }}
                                    >
                                      {"отмена"}
                                    </ModalDecline>
                                  </ModalButtonsRow>
                                </HabitIRedactor>
                              </>
                            )}
                            {j === habits.habits.length - 1 && (
                              <AddHabitI
                                isMobile={isMobile}
                                onClick={() => {
                                  dispatch(showNewHabitModal(true));
                                }}
                              >
                                {"Добавить привычку"}
                              </AddHabitI>
                            )}
                          </>
                        );
                      })}
                    </HabitIActivityRow>
                  );
                })}
              </HabitIActivityContainer>
            </HabitIGrandContainer>
          </>
        ) : (
          <>
            <HabitIGrandContainer
              style={{
                marginBottom: "max(-60px, -3vw)",
                backgroundColor: "transparent",
                boxShadow: "none",
                marginTop: isMobile ? "min(50px, 2.5vw)" : "",
              }}
            >
              <HabitIActivityContainer>
                <HabitIActivityRow
                  monthWidth={date.daysArray.length}
                  nonHover={true}
                  // style={{ backgroundColor: "yellow" }}
                >
                  {!habits.habits.length && (
                    <AddHabitI
                      isMobile={isMobile}
                      onClick={() => {
                        dispatch(showNewHabitModal(true));
                      }}
                    >
                      {"Добавить привычку"}
                    </AddHabitI>
                  )}
                  {[
                    "Привычка",
                    ...date.daysArray,
                    // ...new Array(31 - date.daysArray.length).fill("fake"),
                  ].map((el, i) => {
                    return i && el !== "fake" ? (
                      <HabitIActivityDay
                        first={i === 1}
                        isFuture={el > date.day}
                        isToday={el === date.day}
                      >
                        {el}
                      </HabitIActivityDay>
                    ) : el === "fake" ? (
                      <HabitIFakeDay></HabitIFakeDay>
                    ) : (
                      <HabitIHabitNameHeader isMobile={isMobile}>
                        {el}
                      </HabitIHabitNameHeader>
                    );
                  })}
                </HabitIActivityRow>
              </HabitIActivityContainer>
            </HabitIGrandContainer>
            <HabitIGrandContainer>
              <HabitIActivityContainer>
                {habits.habits.map((habit, j) => {
                  return (
                    <HabitIActivityRow monthWidth={date.daysArray.length}>
                      {[habit.title, ...date.daysArray].map((el, i) => {
                        // const status = [true, false][Math.floor(Math.random() * 3)];
                        const dateTmp = date.dateStr.split(".");
                        const dateRes = `${el}.${dateTmp[1]}.${dateTmp[2]}`;
                        let status;
                        let ok;
                        try {
                          status = habits?.habitChecks[habit.id][dateRes];
                          ok = habits?.habitChecks[habit.id][dateRes];
                        } catch {
                          status = null;
                          ok = null;
                        }

                        const isFuture = el > date.day;
                        const isToday = el === date.day;
                        return i && el !== "fake" ? (
                          <>
                            <HabitIActivityDefault
                              style={{
                                zIndex:
                                  modals.clicked[0] === i &&
                                  modals.clicked[1] === j
                                    ? 20
                                    : 0,
                              }}
                              first={i === 1}
                              last={j === habits.habits.length - 1}
                              isFuture={isFuture}
                              isToday={isToday}
                              status={status}
                              ok={ok}
                              onClick={() => {
                                dRes = dateRes;
                                if (
                                  (!isFuture || isToday) &&
                                  !modals.clicked.length
                                ) {
                                  dispatch(setClicked([i, j]));
                                }
                              }}
                            >
                              {!isFuture && ok
                                ? status === "done"
                                  ? "✓"
                                  : "×"
                                : ""}
                              {modals.clicked[0] === i &&
                                modals.clicked[1] === j && (
                                  <>
                                    <HabitChooseBox out={out}>
                                      <CheckButtonBox
                                        onClick={() =>
                                          checkClickHandler(
                                            habit.id,
                                            "done",
                                            dateRes
                                          )
                                        }
                                      >
                                        <DoneBack></DoneBack>
                                        <DoneButton></DoneButton>
                                      </CheckButtonBox>

                                      <CheckButtonBox
                                        onClick={() =>
                                          checkClickHandler(
                                            habit.id,
                                            "fail",
                                            dateRes
                                          )
                                        }
                                      >
                                        <FailBack></FailBack>
                                        <FailButton></FailButton>
                                      </CheckButtonBox>

                                      <CheckButtonBox
                                        onClick={() =>
                                          checkClickHandler(
                                            habit.id,
                                            null,
                                            dateRes
                                          )
                                        }
                                      >
                                        <NullBack></NullBack>
                                        <NullButton></NullButton>
                                      </CheckButtonBox>
                                    </HabitChooseBox>
                                  </>
                                )}
                              {/* {!isToday && !isFuture && (
                      <HabitIActivityCircle
                        status={status}
                      ></HabitIActivityCircle>
                    )} */}
                            </HabitIActivityDefault>
                          </>
                        ) : el === "fake" ? (
                          <HabitIActivityFake></HabitIActivityFake>
                        ) : (
                          <>
                            <HabitIHabitName
                              border={true}
                              isMobile={isMobile}
                              last={j === habits.length - 1}
                              style={{
                                backgroundColor:
                                  changeId === habit.id
                                    ? habit.color
                                    : habit.color + "99",
                                zIndex: habit.id === changeId ? 10 : 1,
                                fontSize: "min(18px, 0.9vw)",
                              }}
                              onClick={() => {
                                setVal(habit.title);
                                setC(habit.color);
                                setChangeId((prev) =>
                                  prev === habit.id ? null : habit.id
                                );
                              }}
                            >
                              {el}
                            </HabitIHabitName>
                            {habit.id === changeId && (
                              <>
                                <HabitIRedactor ref={rRef}>
                                  {showDel && (
                                    <DeletModal>
                                      <DeleteLabel>
                                        {"Удалить привычку?"}
                                      </DeleteLabel>
                                      <ModalButtonsRow
                                        style={{
                                          marginBottom: "min(20px, 1vw)",
                                          gap: "min(10px, 0.5vw)",
                                        }}
                                      >
                                        <ModalDecline
                                          style={{
                                            fontSize: "min(20px, 1vw)",
                                            height: "min(60px, 3vw)",
                                            lineHeight: "min(60px, 3vw)",
                                          }}
                                          onClick={() => {
                                            fetch(`${habitUrl}/${habit.id}`, {
                                              method: "delete",
                                            });

                                            dispatch(removeHabit(habit.id));
                                            setShowDel(false);
                                            setChangeId(null);
                                          }}
                                        >
                                          {"Да"}
                                        </ModalDecline>
                                        <ModalAccept
                                          onClick={() => {
                                            setShowDel(false);
                                          }}
                                          style={{
                                            fontSize: "min(20px, 1vw)",
                                            height: "min(60px, 3vw)",
                                            lineHeight: "min(60px, 3vw)",
                                          }}
                                        >
                                          {"Нет"}
                                        </ModalAccept>
                                      </ModalButtonsRow>
                                    </DeletModal>
                                  )}
                                  <DeleteIt
                                    onClick={() => setShowDel(true)}
                                  ></DeleteIt>
                                  <ModalLabel
                                    style={{
                                      padding: "min(10px, 0.5vw)",
                                      fontSize: "min(22px, 1.2vw)",
                                      marginTop: "min(10px, 0.5vw)",
                                    }}
                                  >
                                    {"Изменить привычку"}
                                  </ModalLabel>
                                  <HabitIInput
                                    maxLength={"50"}
                                    style={{
                                      backgroundColor: c + "99",
                                    }}
                                    value={val}
                                    onChange={(e) => setVal(e.target.value)}
                                  ></HabitIInput>
                                  <ModalColors
                                    style={{
                                      gap: "0",
                                      justifyContent: "space-between",
                                      marginBottom: "0",
                                    }}
                                  >
                                    {colors.map((el) => {
                                      return (
                                        <ModalColor
                                          style={{
                                            width: "min(52px, 2.6vw)",
                                            height: "min(52px, 2.6vw)",
                                          }}
                                          color={el}
                                          selected={el === c}
                                          onClick={() => {
                                            setC((prev) =>
                                              prev === el ? "#ffffff" : el
                                            );
                                          }}
                                        ></ModalColor>
                                      );
                                    })}
                                  </ModalColors>
                                  <ModalButtonsRow
                                    style={{
                                      marginBottom: "min(20px, 1vw)",
                                      gap: "min(10px, 0.5vw)",
                                    }}
                                  >
                                    <ModalDanger
                                      style={{
                                        fontSize: "min(20px, 1vw)",
                                        height: "min(60px, 3vw)",
                                        lineHeight: "min(60px, 3vw)",
                                      }}
                                      onClick={() => {
                                        fetch(`${habitUrl}`, {
                                          method: "post",
                                          headers: {
                                            "Content-Type": "application/json",
                                          },
                                          body: JSON.stringify({
                                            color: c,
                                            title: val,
                                            id: habit.id,
                                          }),
                                        });

                                        dispatch(
                                          editHabit({
                                            id: habit.id,
                                            title: val,
                                            color: c,
                                          })
                                        );
                                        setShowDel(false);
                                        setChangeId(null);
                                      }}
                                    >
                                      {"изменить"}
                                    </ModalDanger>
                                    <ModalDecline
                                      onClick={() => {
                                        setShowDel(false);
                                        setChangeId(null);
                                      }}
                                      style={{
                                        fontSize: "min(20px, 1vw)",
                                        height: "min(60px, 3vw)",
                                        lineHeight: "min(60px, 3vw)",
                                      }}
                                    >
                                      {"отмена"}
                                    </ModalDecline>
                                  </ModalButtonsRow>
                                </HabitIRedactor>
                              </>
                            )}
                            {j === habits.habits.length - 1 && (
                              <AddHabitI
                                isMobile={isMobile}
                                onClick={() => {
                                  dispatch(showNewHabitModal(true));
                                }}
                              >
                                {"Добавить привычку"}
                              </AddHabitI>
                            )}
                          </>
                        );
                      })}
                    </HabitIActivityRow>
                  );
                })}
              </HabitIActivityContainer>
            </HabitIGrandContainer>
          </>
        )
      ) : (
        <>
          <HabitIGrandContainer
            style={{
              marginBottom: "max(-60px, -3vw)",
              marginTop: "min(60px, 3vw)",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <HabitIActivityContainer>
              <HabitIActivityRow
                style={{
                  backgroundColor: "transparent",
                  borderBottom: "none",
                }}
                monthWidth={date.daysArray.length}
                nonHover={true}
                // style={{ backgroundColor: "yellow" }}
              >
                {qs.map((el) => {
                  return <DiaryQ ok={el.length}>{el}</DiaryQ>;
                })}
              </HabitIActivityRow>
            </HabitIActivityContainer>
          </HabitIGrandContainer>
          <HabitIGrandContainer>
            <HabitIActivityContainer>
              {date.daysArray.map((el, i) => {
                return (
                  <HabitIActivityRow
                    style={{
                      borderBottom:
                        i === date.daysArray.length - 1 ? "" : "none",
                      height: "min(3vw, 60px)",
                    }}
                  >
                    {new Array(qs.length).fill().map((elem, j) => {
                      return (
                        <DiaryQ
                          ok={j}
                          style={{
                            borderTop: "1px solid black",
                            borderLeft: !j ? "1px solid black" : "",
                            fontSize: "min(26px, 1.3vw)",
                            backgroundColor: j
                              ? habits.stat[el]
                                ? "rgb(220 235 144)"
                                : "rgb(222 222 222)"
                              : "white",
                          }}
                        >
                          {j
                            ? habits.stat[el]
                              ? habits.stat[el][j - 1]
                              : ""
                            : el}
                        </DiaryQ>
                      );
                    })}
                  </HabitIActivityRow>
                );
              })}
            </HabitIActivityContainer>
          </HabitIGrandContainer>

          <LabelHui>{"График статистики эффективности сна"}</LabelHui>

          <HabitIGrandContainer
            style={{
              marginBottom: "300px",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            <HabitIActivityContainer ref={kekRef}>
              <svg
                viewBox={`0 0 ${w} ${h}`}
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  position: "absolute",
                  zIndex: 10,
                  opacity: 0.5,
                }}
              >
                {p.length > 1 ? (
                  <>
                    (
                    {p.slice(0, p.length - 1).map((el, i) => {
                      return (
                        <line
                          x1={el.x}
                          y1={el.y}
                          x2={p[i + 1].x}
                          y2={p[i + 1].y}
                          stroke="black"
                          strokeWidth={"min(6px, 0.3vw)"}
                        />
                      );
                    })}
                    {p.map((el, i) => {
                      return (
                        <circle
                          cx={el.x}
                          cy={el.y}
                          r={"min(6px, 0.3vw)"}
                          stroke="black"
                          strokeWidth={"min(6px, 0.3vw)"}
                        />
                      );
                    })}
                    )
                  </>
                ) : p.length === 1 ? (
                  <circle
                    cx={p[0].x}
                    cy={p[0].y}
                    r={"min(6px, 0.3vw)"}
                    stroke="black"
                    strokeWidth={"min(6px, 0.3vw)"}
                  />
                ) : (
                  <></>
                )}
              </svg>
              {kekArray.map((el, i) => {
                return (
                  <HabitIActivityRow
                    style={{
                      backgroundColor: "transparent",
                      borderBottom: "none",
                      height: "min(6px, 0.3vw)",
                    }}
                    monthWidth={date.daysArray.length}
                    nonHover={true}
                    // style={{ backgroundColor: "yellow" }}
                  >
                    <DayS
                      ref={huiRef}
                      style={{ visibility: "hidden", position: "absolute" }}
                    ></DayS>
                    {["", ...date.daysArray].map((elem, j) => {
                      return (
                        <DayS
                          ref={(ell) =>
                            habits.stat[elem]
                              ? (pRefs.current[j] = ell)
                              : (pRefs.current[j] = null)
                          }
                          style={{
                            backgroundColor: !j
                              ? "transparent"
                              : colors2[9 - Math.floor(i / 10)],
                            textAlign: "right",
                            paddingRight: "min(20px, 1vw)",
                            fontSize: j ? "min(40px, 2vw)" : "",
                            borderRight: "min(2px, 0.1vw) solid black",
                            borderTop:
                              j && i % 5 === 0 && i !== kekArray.length - 1
                                ? "min(2px, 0.1vw) solid black"
                                : "",
                            color:
                              j && habits.stat[elem] ? "rgb(122 136 51)" : "",
                          }}
                        >
                          {!j && i % 10 === 0
                            ? 100 - i + "%"
                            : habits.stat[elem]
                            ? 100 - i === habits.stat[elem][qs.length - 1]
                              ? ""
                              : ""
                            : ""}
                        </DayS>
                      );
                    })}
                  </HabitIActivityRow>
                );
              })}
              <HabitIActivityRow
                style={{
                  backgroundColor: "transparent",
                  borderBottom: "none",
                }}
                monthWidth={date.daysArray.length}
                nonHover={true}
              >
                {["", ...date.daysArray].map((elem, j) => {
                  return (
                    <DayS
                      style={{
                        backgroundColor: "transparent",
                        height: "min(40px, 2vw)",
                        lineHeight: "min(40px, 2vw)",
                        borderTop: j ? "1px solid black" : "",
                      }}
                    >
                      {elem}
                    </DayS>
                  );
                })}
              </HabitIActivityRow>
            </HabitIActivityContainer>
          </HabitIGrandContainer>
        </>
      )}
    </>
  );
};

export default HabitImprovedDesktop;
