import "react-perfect-scrollbar/dist/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { showNewHabitModal } from "../../redux/slices/modalSlice";
import HabitCalendar from "../HabitCalendar/HabitCalendar";
import HabitImprovedDesktop from "../HabitImprovedDesktop/HabitImprovedDesktop";
import HabitDesktopHeader from "../HabitDesktopHeader/HabitDesktopHeader";
import {
  HabitDesktopCalendarAndOther,
  HabitDesktopCalendarAndOtherGrand,
} from "./style";
import { HabitListDeskGrandContainer } from "../HabitListDesktop/style";
import HabitListDesktop from "../HabitListDesktop/HabitListDesktop";
import {
  Background,
  HeaderEl,
  HeaderGrand,
  KekW,
  SelectedHeader,
  Selector,
} from "../HabitImprovedDesktop/style";
import {
  habitCheckUrl,
  habitUrl,
  header,
  url,
} from "../../constants/constants";
import { useState } from "react";
import { useEffect } from "react";
import { login, setTeleID, setUsers } from "../../redux/slices/userSlice";
import Init from "../Init/Init";
import { initHabits, setStat } from "../../redux/slices/habitSlice";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router";
import { dateInit } from "../../redux/slices/dateSlice";
import DownloadScreen from "../DownloadScreen/DownloadScreen";

const HabitTracker = ({ opa }) => {
  const dispatch = useDispatch();
  const device = useSelector((store) => store.device);
  const user = useSelector((store) => store.user);
  const date = useSelector((store) => store.date);

  const [download, setDownload] = useState(true);

  const [selected, setSelected] = useState(0);

  const [u, setU] = useState(null);
  const searchQ = new URLSearchParams(document.location.search);
  const navigate = useNavigate();
  const [mail, setMail]=useState()

  const addHabitClick = () => {
    dispatch(showNewHabitModal(true));
  };

  useEffect(() => {
    const kek = async () => {
      const tid = searchQ.get("tid") || localStorage.getItem("tid");
      if (tid) {
        const response = await fetch(`${url}/Diary/get-user-by-id/${tid}`);
        const user = await response.json();

        if (user.isDoc) {
          const regDate = new Date(user.createdAt);
          user.utterDate = [
            regDate.getMonth() + 1,
            regDate.getFullYear(),
            regDate.getDate(),
          ];
          const response4 = await fetch(`${habitUrl}/${user.id}`);
          const habits = await response4.json();

          const response3 = await fetch(`${habitCheckUrl}/${user.id}`);
          const habitChecks = await response3.json();

          // console.log(habitChecks);
          const dateToString = (d) => {
            const [day, month, year] = [
              d.getDate(),
              d.getMonth(),
              `${d.getFullYear()}`.slice(2),
            ];

            return `${day}.${month}.${year}`;
          };

          const d = dateToString(new Date());

          const plank = Math.ceil(habits.length / 2);

          const checkArray = habitChecks.filter((el) => el.date === d);

          dispatch(
            login({
              id: user.id,
              name: user.name,
              surname: user.surname,
              login: user.login,
              role: user.role,
              utterDate: user.utterDate,
              mail: user.mail
            })
          );

          dispatch(dateInit([0, 2023, 1]));

          dispatch(
            initHabits({
              habits,
              habitChecks,
              isPerfectDay: checkArray.length >= plank && habits.length > 0,
            })
          );

          const response2 = await fetch(`${url}/Diary/get-all-by-doc`, {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ docId: tid }),
          });
          const users = await response2.json();

          dispatch(setUsers(users));

          localStorage.setItem("tid", user.tele_id);

          setTimeout(() => {
            setDownload(false);
          }, 1337);

          navigate("/ks1uz6hnmTixCph");
        } else {
          setTimeout(() => {
            setDownload(false);
          }, 1337);
          navigate("/");
        }
      } else {
        setTimeout(() => {
          setDownload(false);
        }, 1337);
        navigate("/");
      }

      // dispatch(setUsers(resp.filter((el) => !el.isDoc)));
    };

    if (opa) {
      kek();
    }
  }, [opa]);

  return (
    <>
      {/* {!opa && <Init />} */}
      {download && <DownloadScreen />}
      <Init />
      <HeaderGrand>
        {/* {header.map((el, i) => {
          return (
            <HeaderEl selected={selected === i} onClick={() => setSelected(i)}>
              {el}
              {selected === i && <SelectedHeader></SelectedHeader>}
            </HeaderEl>
          );
        })} */}
        <HeaderEl>
          {user?.users?.find(el=>el.tele_id == u)?.mail || 'Почта не указана'}
        </HeaderEl>
        {opa && (
          <Selector
            onChange={(e) => {
              setU(e.target.value);
              const kek = async () => {
                const f = await fetch(
                  "http://89.108.88.96:3001/Diary/get-diary-by-user",
                  {
                    method: "put",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ tele_id: e.target.value }),
                  }
                );

                dispatch(setTeleID(e.target.value));

                const resp = await f.json();

                const [day2, m2, y2] = date.dateStr.split(".");

                const ans = {};

                for (let i = 0; i < resp.length; i++) {
                  const d = new Date(resp[i].createdAt);
                  const [day, m, y] = [
                    d.getDate(),
                    `${d.getMonth()}`,
                    `${d.getFullYear()}`.slice(2, 4),
                  ];
                  if (m2 === m && y2 === y) {
                    if (!ans[day]) {
                      ans[day] = [
                        ...JSON.parse(
                          resp[i].answers.replace(/'/gi, '"').replace(/ /gi, "")
                        ),
                        resp[i].Effectivness + "%",
                        resp[i].Effectivness,
                      ];
                    }
                  }
                }

                dispatch(setStat(ans));

                // setStat(stat);
              };

              kek();
            }}
          >
            <option value={null} selected={!u} disabled={true}>
              Пациенты
            </option>
            {user?.users?.map((el) => (
              <option value={el.tele_id} selected={u === el.tele_id}>
                {el.name + " " + (el.surname || "")}
              </option>
            ))}
          </Selector>
        )}
      </HeaderGrand>
      {!isMobile ? (
        <>
          {selected === 1 && (
            <KekW>
              <Background></Background>

              <HabitDesktopCalendarAndOtherGrand
              // style={{ justifyContent: "flex-end", alignItems: "flex-start" }}
              >
                {/* <HabitDesktopCalendarAndOther> */}
                {/* <HabitListDesktop /> */}
                <HabitDesktopHeader selected={selected} />
                <HabitCalendar w={30} type={true} />
                {/* </HabitDesktopCalendarAndOther> */}
              </HabitDesktopCalendarAndOtherGrand>
              <HabitImprovedDesktop type={true} />
            </KekW>
          )}
          {selected === 0 && (
            <KekW>
              <Background></Background>

              <HabitDesktopCalendarAndOtherGrand
              // style={{ justifyContent: "flex-end", alignItems: "flex-start" }}
              >
                {/* <HabitDesktopCalendarAndOther> */}
                {/* <HabitListDesktop /> */}
                <HabitDesktopHeader selected={selected} />
                <HabitCalendar w={30} />
                {/* </HabitDesktopCalendarAndOther> */}
              </HabitDesktopCalendarAndOtherGrand>
              <HabitImprovedDesktop />
            </KekW>
          )}
        </>
      ) : (
        <>
          {selected === 1 && (
            <KekW>
              <Background></Background>

              <HabitDesktopCalendarAndOtherGrand
              // style={{ justifyContent: "flex-end", alignItems: "flex-start" }}
              >
                {/* <HabitDesktopCalendarAndOther> */}
                {/* <HabitListDesktop /> */}
                <HabitDesktopHeader selected={selected} />
                <HabitCalendar w={100} type={true} />
                {/* </HabitDesktopCalendarAndOther> */}
              </HabitDesktopCalendarAndOtherGrand>
              <HabitImprovedDesktop type={true} />
            </KekW>
          )}
          {selected === 0 && (
            <KekW>
              <Background></Background>

              <HabitDesktopCalendarAndOtherGrand
                style={{ flexDirection: "column" }}
                // style={{ justifyContent: "flex-end", alignItems: "flex-start" }}
              >
                {/* <HabitDesktopCalendarAndOther> */}
                {/* <HabitListDesktop /> */}
                <HabitDesktopHeader selected={selected} />
                <HabitCalendar w={100} />
                {/* </HabitDesktopCalendarAndOther> */}
              </HabitDesktopCalendarAndOtherGrand>
              <HabitImprovedDesktop />
            </KekW>
          )}
        </>
      )}
    </>
  );
};

export default HabitTracker;
