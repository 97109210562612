import { useDispatch, useSelector } from "react-redux";
import {
  HabbitITop,
  HabitIContainer,
  HabitIHeader,
  HabitIMonth,
  HabitIMonthChanger,
  HabitIMotivation,
  HabitINextMonth,
  HabitIPrevMonth,
} from "../HabitImprovedDesktop/style";
import { nextMonth, prevMonth, setDateStr } from "../../redux/slices/dateSlice";
import { setStat } from "../../redux/slices/habitSlice";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { setClicked } from "../../redux/slices/modalSlice";

const HabitDesktopHeader = ({ selected }) => {
  const date = useSelector((store) => store.date);
  const user = useSelector((store) => store.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const kek = async () => {
      const f = await fetch(
        "http://89.108.88.96:3001/Diary/get-diary-by-user",
        {
          method: "put",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tele_id: user.tele_id }),
        }
      );

      const resp = await f.json();

      const [day2, m2, y2] = date.dateStr.split(".");

      const ans = {};

      for (let i = 0; i < resp.length; i++) {
        const d = new Date(resp[i].createdAt);
        const [day, m, y] = [
          d.getDate(),
          `${d.getMonth()}`,
          `${d.getFullYear()}`.slice(2, 4),
        ];
        if (m2 === m && y2 === y) {
          if (!ans[day]) {
            ans[day] = [
              ...JSON.parse(
                resp[i].answers.replace(/'/gi, '"').replace(/ /gi, "")
              ),
              resp[i].Effectivness + "%",
              resp[i].Effectivness,
            ];
          }
        }
      }

      dispatch(setStat(ans));
    };

    kek();
  }, [date]);

  return (
    <HabitIContainer>
      {/* <HabbitITop> */}
      <HabitIHeader isMobile={isMobile}>
        {selected === 1 ? "трекер привычек" : "дневник сна"}
      </HabitIHeader>
      {/* </HabbitITop> */}

      <HabitIMonthChanger isMobile={isMobile}>
        <HabitIMonth isMobile={isMobile}>
          {date.month + " " + date.date.getFullYear()}
        </HabitIMonth>
        <HabitIPrevMonth
          isMobile={isMobile}
          isCurrent={date.isLast}
          onClick={async () => {
            if (!date.isLast) {
              dispatch(setClicked([]));
              dispatch(prevMonth([user.utterDate]));
            }
          }}
        >
          {/* ← */}
        </HabitIPrevMonth>
        <HabitINextMonth
          isMobile={isMobile}
          isCurrent={date.isCurrent}
          onClick={async () => {
            if (!date.isCurrent) {
              dispatch(setClicked([]));
              dispatch(nextMonth());
            }
          }}
        >
          {/* → */}
        </HabitINextMonth>
      </HabitIMonthChanger>

      <HabitIMotivation isMobile={isMobile}>
        {selected === 1
          ? `ВСЕ ПОБЕДЫ НАЧИНАЮТСЯ С ПОБЕДЫ НАД САМИМ СОБОЙ`
          : "Сегодня я закладываю фундамент для прекрасного будущего"}
      </HabitIMotivation>
    </HabitIContainer>
  );
};

export default HabitDesktopHeader;
