import { createSlice } from "@reduxjs/toolkit";
import { habits } from "../../hardcode/constants";

const initialState = {
  habits,
  habitChecks: {},
  stat: {},
  fullStat: {},
  ids: [],
  isPerfectDay: false,
};

export const habitSlice = createSlice({
  name: "habit",
  initialState,
  reducers: {
    initHabits: (state, action) => {
      const { habits, habitChecks, isPerfectDay } = action.payload;
      state.isPerfectDay = isPerfectDay;
      state.habits = habits;
      for (let i = 0; i < habits.length; i++) {
        const checks = habitChecks.filter((el) => el.habitId === habits[i].id);
        state.habitChecks[habits[i].id] = {};
        for (let j = 0; j < checks.length; j++) {
          state.habitChecks[habits[i].id][checks[j].date] = checks[j].checked;
        }
        state.ids.push(habits[i].id);
      }
    },
    habitCheck: (state, action) => {
      const { status, id, date } = action.payload;
      if (!status) {
        delete state.habitChecks[id][date];
      } else {
        state.habitChecks[id][date] = status;
      }
    },
    editHabit: (state, action) => {
      const { id } = action.payload;
      const index = state.habits.findIndex((el) => el.id === id);
      state.habits[index] = action.payload;
    },
    perfectDay: (state, action) => {
      state.isPerfectDay = action.payload;
    },
    newHabit: (state, action) => {
      state.habits.push(action.payload);
      state.habitChecks[action.payload.id] = {};
      state.ids.push(action.payload.id);
    },
    removeHabit: (state, action) => {
      state.habits = state.habits.filter((el) => el.id !== action.payload);
      state.ids = state.ids.filter((el) => el !== action.payload);
    },
    setStat: (state, action) => {
      state.stat = action.payload;
    },
  },
});

export const {
  newHabit,
  removeHabit,
  initHabits,
  habitCheck,
  editHabit,
  setStat,
  perfectDay,
} = habitSlice.actions;

export default habitSlice.reducer;
