import { createSlice } from "@reduxjs/toolkit";

const initialState =
  window.innerWidth < window.innerHeight ? "mobile" : "desktop";

export const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    testAndChangeDevice: (state) => {
      state = window.innerWidth < window.innerHeight ? "mobile" : "desktop";
    },
  },
});

export const { testAndChangeDevice } = deviceSlice.actions;

export default deviceSlice.reducer;
