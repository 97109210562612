import { useDispatch } from "react-redux";
import { Background1, ModalContainer } from "../style";
import { showTutor } from "../../redux/slices/modalSlice";
import { isMobile } from "react-device-detect";

const Tutor = () => {
  const dispatch = useDispatch();
  return (
    <Background1 onClick={() => dispatch(showTutor(false))}>
      <ModalContainer
        isMobile={isMobile}
        style={{
          whiteSpace: "pre-wrap",
          textAlign: "center",
          padding: "min(40px, 2vw)",
        }}
      >{`Трекер привычек от Доктора Даши для здорового сна

Формирование правильных привычек — сложное искусство, в котором трудно достичь мастерства, в отличие от формирования плохих привычек. Они формируются просто и могут завладеть нашей жизнью, что в конечном итоге сделает её очень неприятной.

Инструкция:
Используя кнопку "Добавить привычку" (зеленая, под таблицей) вы можете внести свои привычки, добавив их к текущему списку.

Если в списке есть лишние, на ваш взгляд, привычки - вы можете их удалить. Для этого нажмите, однократно, на текст привычки и перед вами откроется окно редактора. В правом верхнем углу будет кнопка удаления (мусорное ведро). Нажмите на него и подтвердите решение.

Так же вы можете отредактировать внешний вид (цвет) каждой привычки, для комфортного визуального различия. 

Каждая привычка может быть отмечена в любой день, с момента её создания и до текущего дня. Наперёд заполнить нельзя. Чтобы отметить статус привычки  нажмите на ячейку, которая ей соответствует, за текущий или прошедший день. Появится маленькая форма состоящая из трёх позиций (галочка - успешно, крестик - неуспешно, вопрос - пустая клетка (на случай если в данный день привычку не нужно выполнять). 

Приятного использования!`}</ModalContainer>
    </Background1>
  );
};

export default Tutor;
