import { createSlice } from "@reduxjs/toolkit";
import { addDays, endOfMonth, startOfMonth } from "date-fns";
import { months } from "../../constants/constants";

const initialState = {
  date: new Date(),
  dateStr: "",
  dateNowStr: "",
  month: months[new Date().getMonth()],
  isCurrent: true,
  isLast: true,
  daysArray: new Array(31).fill(),
  day: 0,
  firstDay: 1,
  selectedDay: new Date().getDate(),
  isDayComplete: false,
};

const dateToString = (d) => {
  const [day, month, year] = [
    d.getDate(),
    d.getMonth(),
    `${d.getFullYear()}`.slice(2),
  ];

  return `${day}.${month}.${year}`;
};

const dayToString = (str, d) => {
  const split = str.split(".");
  split[0] = d;

  return split.join(".");
};

export const dateSlice = createSlice({
  name: "date",
  initialState,
  reducers: {
    dateInit: (state, action) => {
      const d = new Date();
      const mWidth = endOfMonth(d).getDate();
      state.dateStr = dateToString(d);
      state.dateNowStr = state.dateStr;
      state.date = d;
      state.month = months[d.getMonth()];
      state.isCurrent = true;
      state.isLast =
        d.getMonth() === action.payload[0] &&
        d.getFullYear() === action.payload[1];
      state.day = d.getDate();
      state.firstDay = action.payload[2];
      state.daysArray = new Array(mWidth).fill().map((_, i) => i + 1);
      state.isDayComplete = action.payload[3];
    },
    prevMonth: (state, action) => {
      const d = addDays(startOfMonth(state.date), -2);
      const mWidth = endOfMonth(d).getDate();
      state.dateStr = dateToString(d);
      state.date = d;
      state.month = months[d.getMonth()];
      state.isCurrent = false;
      state.isLast =
        d.getMonth() === action.payload[0] &&
        d.getFullYear() === action.payload[1];
      state.day = 33;
      state.daysArray = new Array(mWidth).fill().map((_, i) => i + 1);
      state.selectedDay = mWidth;
      state.isDayComplete = action.payload[3];
    },
    nextMonth: (state, action) => {
      const d = addDays(endOfMonth(state.date), 2);
      const mWidth = endOfMonth(d).getDate();
      state.dateStr = dateToString(d);
      state.date = d;
      state.month = months[d.getMonth()];
      const today = new Date();
      const current =
        d.getMonth() === today.getMonth() &&
        d.getFullYear() === today.getFullYear();
      state.isCurrent = current;
      state.isLast = false;
      state.day = current ? today.getDate() : 33;
      state.daysArray = new Array(mWidth).fill().map((_, i) => i + 1);
      state.selectedDay = current ? new Date().getDate() : mWidth;
      state.isDayComplete = action.payload;
    },
    changeSelectedDay: (state, action) => {
      state.dateStr = dayToString(state.dateStr, action.payload.date);
      state.selectedDay = action.payload.date;
      state.isDayComplete = action.payload.complete;
    },
    setDateStr: (state, action) => {
      const date = action.payload;
      state.dateStr = `${1}.${date.getMonth()}.${date.getFullYear()}`;
    },
  },
});

export const { prevMonth, nextMonth, dateInit, changeSelectedDay, setDateStr } =
  dateSlice.actions;

export default dateSlice.reducer;
