import { addDays, endOfMonth, startOfMonth } from "date-fns";
import {
  CalendarBody,
  CalendarContainer,
  CalendarDay,
  CalendarGrandContainer,
  CalendarHead,
  CalendarLabel,
  CalendarRow,
  FlexDay,
} from "./style";
import { useEffect, useState, useRef } from "react";
import { arc } from "d3-shape";
import FrontBar from "../../d3/fron-bar/bar";
import { colors } from "../../constants/styleValues";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedDay } from "../../redux/slices/dateSlice";
import { isMobile } from "react-device-detect";

const HabitCalendar = ({ w, type }) => {
  const cRef = useRef();

  const date = useSelector((store) => store.date);
  const habits = useSelector((store) => store.habits);

  const [habitStatus, setHabitStatus] = useState({});

  const [size, setSize] = useState(0);

  const dispatch = useDispatch();
  const makeMonthCalendar = (cDate) => {
    const startDay = startOfMonth(cDate).getDay() || 7;
    const endOfPrevMonth = endOfMonth(
      addDays(startOfMonth(cDate), -1)
    ).getDate();
    const endDay = endOfMonth(cDate).getDate();

    let currentDay = endOfPrevMonth - startDay + 2;
    let isPrevMonth = true;
    let isNextMonth = false;
    if (currentDay > endOfMonth) {
      currentDay = 1;
      isPrevMonth = false;
    }

    const res = [["пн", "вт", "ср", "чт", "пт", "сб", "вс"]];

    for (let i = 0; i < 6; i++) {
      const row = [];
      for (let j = 0; j < 7; j++) {
        if (isPrevMonth && currentDay > endOfPrevMonth) {
          currentDay = 1;
          isPrevMonth = false;
        }
        if (!isPrevMonth && currentDay > endDay) {
          currentDay = 1;
          isNextMonth = true;
        }
        row.push({
          day: currentDay,
          enabled:
            !(isPrevMonth || isNextMonth) &&
            (date.isLast ? (date.firstDay <= currentDay ? true : false) : true),
        });
        currentDay++;
      }
      res.push(row);
    }

    setCalendar(res);
  };

  const setArcs = () => {
    const w = dayRef.current.offsetWidth;
    const arc = Math.floor(w / 100);
    setSvgWidth(w);
    setArcOuterRadius(w / 2.5);
    setArcInnerRadius(w / 2 - arc);
  };

  const [calendar, setCalendar] = useState(null);
  const dayRef = useRef();

  const [svgWidth, setSvgWidth] = useState(0);
  // const [arcWidth, setArcWidth] = useState(0);
  const [arcInnerRadius, setArcInnerRadius] = useState(0);
  const [arcOuterRadius, setArcOuterRadius] = useState(0);

  const tau = 2 * Math.PI; // http://tauday.com/tau-manifesto

  const arcGenerator = arc()
    .innerRadius(arcInnerRadius)
    .outerRadius(arcOuterRadius)
    .startAngle(0)
    .cornerRadius(5);

  window.addEventListener("resize", () => {
    // setArcs();
  });

  const dayButtonClick = (index) => {
    dispatch(changeSelectedDay({ date: index }));
    // setArcs();
  };

  useEffect(() => {
    makeMonthCalendar(date.date);
  }, [date.month]);

  useEffect(() => {
    if (calendar && dayRef.current) {
      setArcs();
    }
  }, [calendar, dayRef]);

  useEffect(() => {
    makeMonthCalendar(new Date());
    window.addEventListener("resize", () => {
      setSize(cRef.current.offsetWidth / 10);
    });
  }, []);

  useEffect(() => {
    if (cRef.current) {
      setSize(cRef.current.offsetWidth / 10);
    }
  }, [cRef]);

  useEffect(() => {
    const day = date.isCurrent ? date.day : date.daysArray.length;
    const dateStrTmp = date.dateStr.split(".");
    const checks = {};
    for (let i = 1; i <= day; i++) {
      const dateStr = `${i}.${dateStrTmp[1]}.${dateStrTmp[2]}`;
      checks[i] = { count: 0, ok: 0 };
      for (let j = 0; j < habits.habits.length; j++) {
        if (habits.habitChecks[habits.habits[j].id][dateStr]) {
          checks[i].count += 1;
          if (habits.habitChecks[habits.habits[j].id][dateStr] === "done") {
            checks[i].ok += 1;
          }
        }
      }
    }
    setHabitStatus(checks);
  }, [habits, date]);

  return (
    <CalendarGrandContainer
      style={{ width: `${w}%`, maxWidth: isMobile ? "1200px" : "" }}
      ref={cRef}
    >
      <CalendarContainer>
        {/* <CalendarHead>{date.month}</CalendarHead> */}
        <CalendarRow style={{ backgroundColor: "rgb(255, 228, 118)" }}>
          {calendar &&
            calendar[0].map((d) => (
              <CalendarLabel size={size} isMobile={isMobile}>
                {d}
              </CalendarLabel>
            ))}
        </CalendarRow>
        <CalendarBody>
          {calendar?.slice(1)?.map((el, i) => {
            return (
              <CalendarRow key={i}>
                {el?.map((d, j) => {
                  const ok = habitStatus[d.day]?.count;
                  let r = 0;
                  if (ok) {
                    r = Math.floor(
                      (habitStatus[d.day].ok / habitStatus[d.day].count) * 100
                    );
                  }
                  const c = r < 26 ? 0 : r < 51 ? 1 : r < 100 ? 2 : 3;
                  return (
                    <CalendarDay
                      ref={dayRef}
                      key={j}
                      enabled={d.enabled}
                      isFuture={d.day > date.day}
                      isToday={d.day === date.day}
                      isMobile={isMobile}
                      size={size}
                      selected={date.selectedDay === d.day && d.enabled}
                      c={
                        d.enabled && ok
                          ? // (d.enabled && date.isLast && d.day > date.firstDay)
                            colors.gr1[c]
                          : d.enabled && !ok
                          ? "rgb(197 197 197)"
                          : "black"
                      }
                      type={!type}
                      ok={habits.stat[d.day]}
                      // onClick={() => {
                      //   if (d.enabled) {
                      //     dayButtonClick(d.day);
                      //   }
                      // }}
                    >
                      {d.enabled && d.day <= date.day && type && (
                        <FlexDay>
                          <svg height={svgWidth} width={svgWidth}>
                            <g
                              className="background-bar-group"
                              transform={`translate(${svgWidth / 2}, ${
                                svgWidth / 2
                              })`}
                            >
                              <path
                                d={arcGenerator({ endAngle: tau })}
                                opacity="1"
                                fill="transparent"
                              />
                            </g>
                            <FrontBar
                              arcGenerator={arcGenerator}
                              value={d.enabled && ok ? r : 0}
                              // today={d.enabled && d.day === date.day}
                              tau={tau}
                              svgWidth={svgWidth}
                              svgHeight={svgWidth}
                            />
                          </svg>
                        </FlexDay>
                      )}
                      {d.day}
                    </CalendarDay>
                  );
                })}
              </CalendarRow>
            );
          })}
        </CalendarBody>
      </CalendarContainer>
    </CalendarGrandContainer>
  );
};

export default HabitCalendar;
