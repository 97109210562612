import React from "react";
import { select } from "d3-selection";
import { interpolate } from "d3-interpolate";
import { transition } from "d3-transition";
import { colors } from "../../constants/styleValues";

const arcTween = (newAngle, arcGenerator) => (d) => {
  const customInterpolate = interpolate(d.endAngle, newAngle);
  return (t) => {
    d.endAngle = customInterpolate(t);
    return arcGenerator(d);
  };
};

export default class Bar extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }
  componentDidMount() {
    this.init();
  }
  componentDidUpdate() {
    this.transitionBar();
  }
  init() {
    const node = this.ref.current;
    const { tau } = this.props;
    const initialValue = 0;

    const g = select(node)
      .append("path")
      .style("fill", "transparent")
      .datum({ endAngle: (initialValue / 100) * tau });

    this.g = g;
    this.transitionBar();
  }
  transitionBar() {
    const { g } = this;
    const t = transition().duration(800);
    const { value, tau, arcGenerator } = this.props;

    g.transition(t)
      .duration(0)
      .attrTween("d", arcTween((value * tau) / 100, arcGenerator))
      .style(
        "fill",
        this.props.today
          ? "#c1c1c1"
          : colors.gr[
              this.props.value < 26
                ? 0
                : this.props.value < 51
                ? 1
                : this.props.value < 100
                ? 2
                : 3
            ]
      );
  }
  render() {
    const { svgWidth, svgHeight } = this.props;
    return (
      <g
        className="front-bar-group"
        transform={`translate(${svgWidth / 2}, ${svgHeight / 2})`}
        ref={this.ref}
      ></g>
    );
  }
}
