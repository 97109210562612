import styled from "styled-components";

export const MainBlock = styled.div`
  display: flex;
  background-color: aliceblue;
  justify-content: center;
`;

export const QuestionBlock = styled.div`
  display: flex;
  justify-content: center;
  background-color: #8cecec;
  align-content: center;
  rotate: 45deg;
`;

export const TableBlock = styled.div``;
