export const isPerfectLine = (ids, line, date) => {
  let count = 0;
  const plank = Math.ceil(ids.length / 2);

  for (let i = 0; i < ids.length; i++) {
    if (line[ids[i]][date]) {
      count += 1;
    }
  }

  return date && count >= plank;
};
