import styled from "styled-components";


export const MobileHeaderMainPage = styled.div`
    background-color: #9f7dc0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid black;
    height: 10vh;
    width: 100%;
    position:fixed;
    top:0px;
    z-index: 10;
`
//Ограничит максимальной величиной экрана
export const MobileBodyMainPage = styled.div`
    background-color: #54ebe8;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* min-height: 50vh; */
    
    
`
export const MobileFooterMainPage = styled.div`
    background-color: #4ebef6;
    display: flex;
    justify-content: center;
    height: 20vh;
`

export const MobileMenuBar = styled.div`
    visibility: hidden;
    background: url(${props=>props.url});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #7b717b;
    display: flex;
    justify-content: center;
    height: 6vh;
    width: 7vh;
    border-radius: 8px;
    border: 2px solid #d3ec46;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 20px;
    margin-left: 10px;
    opacity: 0.5;
    &:hover{
        background-color: #2bb197;
        opacity: 1;
        cursor: pointer;
    }
`

export const MobileWelcomeBlock = styled.div`
    display: flex;
    justify-content: center;
    width: 30%;
    aspect-ratio: 3/4;
    /* margin-left: 100px; */
    background-image: url(${props => props.welcomeImage});
    background-size: cover;
    background-repeat: no-repeat;
`

export const MobileEnterButton = styled.div`
    background-image: url(${props => props.enter});
    background-position: center;
    background-repeat: no-repeat;
    background-color: #7b717b;
    display: flex;
    justify-content: center;
    height: 6vh;
    width: 8vh;
    margin: 15px;
    border-radius: 8px;
    border: 2px solid;
    border-color: #d3ec46;
    opacity: 0.5;
    &:hover{
        opacity: 1;
        background-color: #2bb197;
        cursor: pointer;
    }
`

export const MobileMainBlock = styled.div`
    display: flex;
    justify-content: center ;
    flex-direction: column;
    /* position:fixed; */
    margin-top: 10vh;
    width: ${props => props.width};
    height: ${props => props.height};
`

export const MobileDemoTracker = styled.div`
    background-color: #90c3f0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    height: 30vh;
    background-image: url(${props => props.demo});
`
export const MobileConnectButton = styled.div`
    display: flex;
    background-color: red;
    justify-content: center;
    border-radius: 30px;
    align-self: center;
    align-items: center;
    width: 300px;
    height: 50px;
    font-size: 24px;
    margin-top: auto;
    margin-bottom: 10vh;
    &:hover{
        cursor: pointer;
        background-color: #2bb197;
    }

`
export const MobileWelcomeTextBlock = styled.div`
    margin-top: -50px ;
    background-color: aqua;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 18px;    
    width: 70%;
`

export const MobileTestTreckerButton = styled.div`
    display: flex;
    background-color: red;
    justify-content: center;
    border-radius: 30px;
    align-self: center;
    align-items: center;
    width: 300px;
    height: 50px;
    font-size: 24px;
    margin-top: auto;
    margin-bottom: 1vh;
    &:hover{
        cursor: pointer;
        background-color: #2bb197;
    }
`
export const MobileInfoFooterBlock = styled.div`
    display: flex;
    justify-content: start;
    background-color: #565632;
    width: 34.3%;
    flex-direction: column;
    text-align: center;
`

export const MobileModalWindow = styled.div`
    visibility: ${props=>props.active ? "visible":"hidden"};
    display: flex;
    justify-content: center;
    background-color: white;
    z-index: 10;
    border-radius: 20px;
    align-items: center;
    position: fixed;
    padding: 20px;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;

`

export const MobileGradientBlock = styled.div`
    margin-top: -50px;
    height: 100px;
    width: 100%;
    background: linear-gradient(to top, aqua 50%, transparent );
`