export const localTest = true;

export const months = {
  0: "Январь",
  1: "Февраль",
  2: "Март",
  3: "Апрель",
  4: "Май",
  5: "Июнь",
  6: "Июль",
  7: "Август",
  8: "Сентябрь",
  9: "Октябрь",
  10: "Ноябрь",
  11: "Декарь",
};

export const qs = [
  "",
  "Оцените Ваше настроение в данный момент?",
  "Качество Вашего сна прошлой ночью?",
  "Во сколько Вы легли вчера в кровать?",
  "Во сколько выключили свет и решили заснуть?",
  "Продолжительность засыпания.  Сколько времени ушло на засыпание (в мин)?",
  "Частота пробуждений. Сколько раз за ночь Вы просыпались, не считая утреннего подъема?",
  "Общее время ночного бодрствования (не считая времени, которое потребовалось, чтобы заснуть вечером), (в мин)",
  "Во сколько Вы проснулись утром?",
  "Во сколько встали с кровати?",
  "Эффективность сна",
];

export const url = "http://89.108.88.96:3001";

export const userUrl = `${url}/8dgcmJ2Eny`;
export const habitUrl = `${url}/ftf6w14kc8`;
export const habitCheckUrl = `${url}/R4okJj4gvl`;

export const header = ["Дневник сна"];

export const colors = [
  "#f585a2",
  "#f585f0",
  "#a185f5",
  "#85bcf5",
  "#85f5db",
  "#9bf585",
  "#f5ee85",
  "#f59b85",
];

export const colors2 = [
  "#f89696",
  "#f8ba96",
  "#f8d696",
  "#f8e896",
  "#edf896",
  "#d3f896",
  "#b2f896",
  "#96f896",
  "#96f896",
  "#96f896",
];

export const motivation = [
  "Произнеси фразу вслух: «Я архитектор своей жизни. Я создатель моей реальности»",
  "Произнеси фразу вслух: «Я принимаю и люблю себя такой, какая я есть»",
  "Сегодня будет сказочный день. Верю в тебя.",
  "Произнеси фразу вслух: «Я могу достичь всего, чего захочу»",
  "Произнеси фразу вслух: «Я становлюсь увереннее и сильнее с каждым днем»",
  "Произнеси фразу вслух: «Я становлюсь все более осведомленной и мудрой с каждым днем»",
  "Произнеси фразу вслух: «Я благодарна за чудеса в моей жизни»",
  "Произнеси фразу вслух: «Вселенная поддерживает меня всеми возможными способами»Произнеси фразу вслух: «Каждый опыт в моей жизни помогает мне расти»",
  "Произнеси фразу вслух: «Сегодня я закладываю фундамент для прекрасного будущего»",
  "Произнеси фразу вслух: «Я достойна счастья и любви»",
  "Произнеси фразу вслух: «Я окружена любовью. Я привлекаю добрых людей»",
  "Произнеси фразу вслух: «Я становлюсь все более и более процветающей с каждым днем»",
  "Произнеси фразу вслух: «Я могу найти позитив в любой ситуации»",
  "Произнеси фразу вслух: «В каждой ситуации есть что-то хорошее, даже если я сейчас это не вижу»",
  "Произнеси фразу вслух: «Всегда есть другой путь. Всегда есть решение моих проблем»",
  "Произнеси фразу вслух: «Я отпустила гнев. Это помогает мне принимать лучшие решения и видеть вещи более четко»",
  "Произнеси фразу вслух: «Каждый раз, когда я выдыхаю, я выдыхаю напряжение и беспокойство»",
  "Произнеси фразу вслух: «Я верю в себя. Я в состоянии встретить лучшее возможное решение»",
  "Я верю в твою способность создавать сказочное будущее",
  "У тебя есть все, что нужно, чтобы твои мечты стали реальностью",
  "Я верю в твою способность двигаться дальше. Вперед!",
  "Произнеси фразу вслух: «Я верю в свою способность воплотить свои мечты, несмотря ни на что»",
  "Произнеси фразу вслух: «Я успешна во всем, что я делаю»",
  "Произнеси фразу вслух: «Меня ждет самый лучший день»",
  "Произнеси фразу вслух: «Я благодарна Вселенной за новый, прекрасный день моей жизни»",
  "Произнеси фразу вслух: «Мой мир полон счастья, радости и изобилия»",
  "Произнеси фразу вслух: «У меня все хорошо, и с каждым днем моя жизнь становится еще лучше»",
  "Произнеси фразу вслух: «Я мыслю позитивно и создаю для себя прекрасную и успешную жизнь»",
  "Произнеси фразу вслух: «У меня есть знания, чтобы принимать разумные решения для себя»",
  "Произнеси фразу вслух: «Я осознаю свою ценность, моя уверенность растет»",
  "Произнеси фразу вслух: «Вселенная дает мне самое лучшее»",
  "Произнеси фразу вслух: «Меня окружают позитивные и понимающие люди, которые верят в меня»",
  "Произнеси фразу вслух: «У меня есть все, что мне нужно, чтобы сделать сегодняшний день отличным»",
  "Произнеси фразу вслух: «Я сильная женщина, которая притягивает успех и счастье»",
  "Произнеси фразу вслух: «У меня есть сила, чтобы добиться успеха и процветания, которых я желаю»",
  "Произнеси фразу вслух: «Я живу в полную силу»",
  "Ты заслуживаешь быть счастливой и любимой",
  "Произнеси фразу вслух: «Я смелая. Я готов действовать и противостоять своим страхам»",
  "Произнеси фразу вслух: «Я всегда притягиваю только хорошее и имею в своей жизни самых лучших позитивных людей»",
  "Произнеси фразу вслух: «Я магнит, притягивающий счастье, любовь, здоровье и процветание»",
  "Произнеси фразу вслух: «Я доверяю своей интуиции и всегда принимаю правильные решения»",
  "Произнеси фразу вслух: «Я преодолею все препятствия с легкостью»",
  "Сегодня твой день, тебе улыбается удача",
  "Произнеси фразу вслух: «Мои мысли и мечты всегда сбываются»",
  "Произнеси фразу вслух: «Я успешна, моя удача всегда со мной»",
  "Произнеси фразу вслух: «Я всегда получаю то, чего хочу»",
  "Произнеси фразу вслух: «Каждый день я получаю от жизни сюрпризы»",
  "Произнеси фразу вслух: «Я с радостью встречаю этот самый удачный день»",
  "Произнеси фразу вслух: «Я обладаю особенными талантами и умело использую их во благо себе и другим»",
  "Произнеси фразу вслух: «Все, в чем я нуждаюсь, приходит ко мне в нужный день и час»",
  "Произнеси фразу вслух: «Каждая моя мысль создает мое будущее»",
  "Произнеси фразу вслух: «Я нахожусь в процессе позитивных перемен»",
  "Произнеси фразу вслух: «Мне даны бесконечные таланты, которые я начинаю использовать сегодня»",
  "Произнеси фразу вслух: «Я на пути к жизни своей мечты»",
  "Произнеси фразу вслух: «С каждым днем я приближаюсь к своим целям»",
  "Произнеси фразу вслух: «Счастье — это выбор, и сегодня я выбираю быть счастливой»",
  "Произнеси фразу вслух: «Я с радостью встречаю новый день»",
  "Произнеси фразу вслух: «Я забочусь о себе и уделяю себе внимание, потому что заслуживаю этого»",
  "Произнеси фразу вслух: «Мои мысли не управляют мной, я управляю своими мыслями»",
  "Произнеси фразу вслух: «Моё отношение к себе зависит только от меня и ни от кого другого»",
  "Произнеси фразу вслух: «Я знаю, что могу добиться всего, чего захочу»",
  "Произнеси фразу вслух: «Я могу. Я сделаю. И точка»",
  "Произнеси фразу вслух: «Любовь к себе — это моё естественное состояние»",
  "Произнеси фразу вслух: «Я не даю моим страхам управлять мной»",
  "Произнеси фразу вслух: «Нет таких препятствий, которые я не сумею преодолеть»",
  "Произнеси фразу вслух: «Время — это самое ценное, что у меня есть, и я отношусь к нему бережно»",
  "Сегодняшний день полон возможностей. Удачи!",
  "Произнеси фразу вслух: «Я доверяю своей внутренней мудрости»",
  "Произнеси фразу вслух: «Я начинаю день со спокойствием и благодарностью»",
  "Произнеси фразу вслух: «Я знаю, что каждый день – это благословение и подарок»",
  "Произнеси фразу вслух: «Начинаю свой день с хорошим настроением и вдохновляющими мыслями»",
  "Произнеси фразу вслух: «Если я могу изменить свои мысли, я могу изменить что угодно.",
  "Произнеси фразу вслух: «Мне не нужно ничье одобрение, кроме моего»",
  "Произнеси фразу вслух: «Я добьюсь больших успехов маленькими шагами»",
  "Произнеси фразу вслух: Мои самые большие трудности – это мои самые большие уроки»",
  "Произнеси фразу вслух: «Я буду любить себя так, как я хочу, чтобы другие любили меня. Они не могут сделать для меня того, чего я не сделаю для себя»",
  "Произнеси фразу вслух: «Я верю в себя. Я способен добиться всего, что захочу»",
  "Произнеси фразу вслух: «Я разрешаю себе делать то, что хочу»",
  "Произнеси фразу вслух: «Я архитектор своей жизни»",
  "Произнеси фразу вслух: «Творческая энергия бурлит во мне и приводит к новым блестящим идеям»",
  "Произнеси фразу вслух: «Я могущественный творец. Я создаю жизнь, которую хочу, и наслаждаюсь ею»",
  "Произнеси фразу вслух: «Меня окружают поддерживающие, позитивные люди, которые верят в меня и хотят видеть мой успех»",
  "Произнеси фразу вслух: «Я сильный человек, который привлекает успех и счастье»",
  "Произнеси фразу вслух: «Моя жизнь – это взрыв новых возможностей, потому что я никогда не прекращаю творить»",
  "Произнеси фразу вслух: «Мой разум свободен от неуверенности в себе, и я готов принять все вызовы, которые встречаются на моем пути»",
  "Произнеси фразу вслух: «Меня окружают люди, которые подталкивают меня к тому, чтобы стать лучше»",
  "Произнеси фразу вслух: «Каждая проблема, с которой я сталкиваюсь, – это возможность расти и совершенствоваться»",
  "Произнеси фразу вслух: «Мне можно»",
  "Произнеси фразу вслух: «Со мной все в порядке»",
];
