import { useDispatch } from "react-redux";
import { motivation } from "../../constants/constants";
import { Background1 } from "../../modals/style";
import { Background } from "../HabitImprovedDesktop/style";
import { MotGrand, MotText } from "./style";
import { setShowMotivation } from "../../redux/slices/modalSlice";
import { useState } from "react";
const phrase = motivation[Math.floor(Math.random() * motivation.length)];

const MotivationModal = () => {
  const dispatch = useDispatch();

  const [out, setOut] = useState(false);
  const [idle, setIdle] = useState(false);

  return (
    <>
      <Background1
        out={out}
        onClick={() => {
          setIdle(false);
          setOut(true);
          setTimeout(() => {
            dispatch(setShowMotivation(false));
            setOut(false);
          }, 137);
        }}
      ></Background1>
      <MotGrand
        out={out}
        idle={idle}
        onAnimationEnd={() => {
          setIdle(true);
        }}
        onClick={() => {
          setIdle(false);
          setOut(true);
          setTimeout(() => {
            dispatch(setShowMotivation(false));
            setOut(false);
          }, 137);
        }}
      >
        <MotText>{phrase}</MotText>
      </MotGrand>
    </>
  );
};

export default MotivationModal;
